.GetInvolvedCardBody {
  padding: 1rem 0rem 3rem;
}

.GetInvolvedCardImage {
  width: 100%;
}

.getInvolvedCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  max-width: 300px;
  height: 100%;
}

.GetInvolvedCardTitle {
  font-size: 2rem;
  padding-bottom: 2rem;
  font-weight: 500;
  color: var(--primaryBlue);
}

.GetInvolvedCardText {
  font-size: 1rem;
  color: var(--primaryBlue);
}

.getInvolvedCard-btnWrapper {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

@media (max-width: 1024px) {
  .GetInvolvedCardTitle {
    font-size: 2rem;
    padding-bottom: 1.25rem;
  }

  .GetInvolvedCardText {
    font-size: 1rem;
  }

  .getInvolvedCard {
    max-width: 240px;
  }
}

@media (max-width: 768px) {
  .GetInvolvedCardTitle {
    font-size: 2rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0;
  }

  .GetInvolvedCardText {
    font-size: 1rem;
  }
}

@media (max-width: 767px) {
  .getInvolvedCard {
    align-items: center;
    border: none;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }

  .GetInvolvedCardImage {
    width: 50%;
    display: none;
  }

  .GetInvolvedCardBody {
    padding: 0;
  }

  .getInvolvedCard-btnWrapper {
    padding-top: 1rem;
  }
}
