.subHeader {
  background-color: var(--secondaryBlue);
  padding: 0.6rem 5rem;
}
.subHeader h6 {
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 600;
  text-align: center;
  color: var(--white);
}

@media (max-width: 1200px) {
  .subHeader h6 {
    font-size: 1.3rem;
  }

  .subHeader {
    background-color: var(--secondaryBlue);
    padding: 0.6rem 5rem;
    padding-top: calc(60px + 0.6rem);
  }
}

@media (max-width: 1024px) {
  .subHeader h6 {
    font-size: 1.25rem;
  }

  .subHeader {
    padding: 0.6rem 2rem;
    padding-top: calc(60px + 0.6rem);
  }
}

@media (max-width: 768px) {
  .subHeader h6 {
    font-size: 1.125rem;
    line-height: 1.25rem;
  }

  .subHeader {
    padding: 0.5rem 1.25rem;
    padding-top: calc(60px + 0.5rem);
  }
}

@media (max-width: 450px) {
  .subHeader h6 {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .subHeader {
    padding: 0.5rem 1.25rem;
    padding-top: calc(60px + 0.5rem);
  }
}

@media (max-width: 350px) {
  .subHeader h6 {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .subHeader {
    padding: 0.5rem 1.25rem;
    padding-top: calc(60px + 0.5rem);
  }
}
