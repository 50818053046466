.secondaryBtn {
  font-weight: 500;
  background-color: var(--white);
  padding: 0.2rem 1.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 8px;
  border: solid 3px var(--orangeButton);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  position: relative;
  text-decoration: none;
  color: var(--foregroundBlue);
  
  cursor: pointer;
}
.secondaryBtn:hover{
  transition: all .2s ease-in-out;
}
.secondaryBtn::after {
  position: absolute;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  background-color: transparent;
  content: "";
  z-index: 2;
  border-radius: 8px;
}

.secondaryBtn:hover::after {
  border: solid 3px var(--orangeButton);

}

.secondaryBtn:hover {
  font-weight: 600;
  color: var(--darkBlue);
}

.secondaryBtn:active:after {
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  border: solid 5px var(--orangeButton);
}

.secondaryBtn > img {
  transform: rotate(270deg);
}

@media (max-width: 450px) {
  .secondaryBtn,
  .secondaryBtnDisabled {
    width: 100%;
    font-size: 1.25rem;
  }
}

.secondaryBtnDisabled {
  font-weight: 500;
  background-color: var(--white);
  padding: 0.2rem 1.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 8px;
  border: solid 3px var(--orangeButton);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  position: relative;
  opacity: 50%;
}

.secondaryBtnDisabled > img {
  transform: rotate(270deg);
}
