#ourPartners {
  padding-top: 60px;
}

.ourPartners {
  padding: 40px 0rem 5rem;
}

.ourPartnersImage {
  width: 100vw;
}

.ourPartnersCol {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}

.ourPartnersLogoWrapper {
  white-space: nowrap;
  padding: 1rem 0;
  overflow: hidden;
  position: relative;
}

.ourPartnersLogoWrapper:before,
.ourPartnersLogoWrapper:after {
  height: 100%;
  content: "";
  z-index: 2;
  width: 15rem;
  position: absolute;
  top: 0;
}

.ourPartnersLogoWrapper:before {
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
  left: 0;
}

.ourPartnersLogoWrapper:after {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  right: 0;
}

.ourPartnersLogoWrapper:hover .ourPartnersLogoSlide {
  animation-play-state: paused;
}

.ourPartnersLogoSlide {
  display: inline-block;
  width: max-content;
  animation: partnersSlide 13s infinite linear;
}

.ourPartnersImages {
  margin: 0 2rem;
  height: 120px;
  width: auto;
}

.ourPartnersHeader {
  font-weight: 600;
  font-size: 1.5rem;
  padding: 0 7.5rem 3rem;
}

.ourPartnersCutImage {
  border-radius: 100%;
}

@keyframes partnersSlide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

@media (max-width: 1200px) {
  .ourPartnersHeader {
    font-size: 1.25rem;
    padding: 0 5rem 2rem;
  }
}

@media (max-width: 1024px) {
  .ourPartnersHeader {
    font-size: 1.25rem;
    padding: 0 2rem 2rem;
  }

  .ourPartners {
    padding: 40px 0rem 5rem;
  }
}

@media (max-width: 768px) {
  .ourPartnersHeader {
    font-size: 1.25rem;
  }

  .ourPartnersHeader {
    font-size: 1.25rem;
    padding: 0 1.25rem 2rem;
  }

  .ourPartners {
    padding: 3rem 0rem 3rem;
  }
}

@media (max-width: 600px) {
  .ourPartnersHeader {
    padding: 0rem 1.25rem 1.5rem;
    font-size: 1rem;
  }

  .ourPartnersHeader {
    font-size: 1rem;
  }

  .ourPartnersLogoWrapper:before,
  .ourPartnersLogoWrapper:after {
    width: 8rem;
  }
}
