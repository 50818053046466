@import url("https://fonts.googleapis.com/css2?family=Aclonica&family=Nunito:wght@300&family=Poppins:wght@200;300;400;500;600;700&display=swap");
.mp-wrapper {
  background: white;
  overflow-y: scroll;
  width: 325px;
  box-shadow: rgba(0, 0, 0, 0.753) 0px 22px 70px 4px;
  height: 95vh;
  font-family: "Poppins", sans-serif;
  z-index: 9999;
  right: 0px;
  /* top: 7vh; */
  /* padding-bottom: 100px; */
  position: absolute;
}

.mp-slider {
  line-height: 24px;
  padding: 5px;
  padding-bottom: 100px;
  background: white;
}
/* 139 */
.map-slider-contain {
  height: 116px;
  border: 0.5px solid #b3b3b3;

  margin-bottom: 3px;
  border-radius: 5px;
}
.mp-img {
  width: 120px;
  height: 100%;
  margin-right: 6px;
}
.mp-header {
  font-size: 16px;
  font-weight: 600;

  color: #191919;
}
.map-slider-details {
  display: flex;
  /* align-items: start; */
  position: relative;
  height: 100%;
}
.map-slider-details {
  height: 100%;
  width: 100%;
}
.mp-location {
  font-size: 14px;
  font-weight: 500;
  margin-top: 13px;
  white-space: nowrap; /* Prevents text from wrapping to the next line */
  overflow: hidden;    /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds ellipsis when text overflows */
  
  line-height: 16px;
}
.mp-btn {
  background: #22a6c1;
  color: white;
  font-size: 12px;
  border-radius: 24px;
  width: fit-content !important;

  display: flex;
  height: 22px;
  padding: 6px 8px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}
.mp-flex {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.mp-more {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.mp-close {
  position: fixed;
  top: 32px;
  right: 305px;
  width: 22px;
  cursor: pointer;
  z-index: 3333;
}
.obs-item:hover {
  background-color: #e5f4ffcc;
}
.selected__observation {
  border: 2px solid #073b60 !important;
}
