.mobileApp {
  background: var(--communityBlue);
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5rem 1.25rem;
}

.mobileAppHeader {
  font-size: 2.5rem;
  font-weight: 600;
  padding-bottom: 2rem;
}

.mobileAppText {
  font-size: 1.25rem;
  width: 600px;
}

.mobileAppBtns {
  background-color: transparent;
  color: var(--white);
  font: 1.25rem;
  font-weight: 600;
  border: 1px solid var(--white);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  position: relative;
}

.mobileAppBtns:hover {
  border: 1px solid transparent;
}

.mobileAppBtns:hover::after {
  position: absolute;
  top: -3px;
  left: -3px;
  border-radius: 0.5rem;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  background-color: rgba(0, 0, 0, 15%);
  content: "";
  border: 3px solid rgba(255, 255, 255, 75%);
}

.mobileAppBtns:active::after {
  position: absolute;
  background-color: rgba(0, 0, 0, 25%);
  border: 2px solid rgb(255, 255, 255);
  content: "";
}

.mobileAppBtns > img {
  position: relative;
  z-index: 2;
}

.mobileAppBtns > span {
  position: relative;
  z-index: 2;
}

.mobileAppBtns:hover {
  color: var(--white);
}

.mobileAppBtns span {
  font-size: 18px;
  display: flex;
  justify-content: flex-start;
}

.mobileAppBtnWrapper {
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
}

.mobileAppIcons {
  height: 2.5rem;
  padding-right: 0.3rem;
  justify-self: start;
}

@media (max-width: 600px) {
  .mobileAppText {
    font-size: 1rem;
    width: 100%;
  }

  .mobileAppBtns span {
    width: 110px;
  }

  .mobileAppHeader {
    font-size: 1.75rem;
    font-weight: 600;
    padding-bottom: 2rem;
    width: 100%;
  }

  .mobileApp {
    padding: 3rem 1.25rem;
  }

  .mobileAppBtnWrapper {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding: 2rem 0 0 0;
  }

  .mobileAppBtns {
    width: 12rem;
    padding: 0.75rem 0.5rem;
    justify-content: space-evenly;
  }
}

@media (max-width: 450px) {
  .mobileAppBtns {
    width: 100%;
    padding: 0.5rem 0rem;
    justify-content: center;
  }

  .mobileAppBtnWrapper {
    width: 100%;
  }

  .mobileAppHeader {
    margin-bottom: 0;
  }

  .mobileAppText {
    font-size: 1rem;
  }

  .mobileApp {
    text-align: left;
  }
}
