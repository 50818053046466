#landingHeader {
  position: fixed;
  z-index: 10;
}

.landingHeader-fillerBlock {
  height: 60px;
  background-color: var(--foregroundBlue);
}

.navbar {
  background-color: var(--seaBlue);
  height: 4.38rem;
}

#landingHeader .header {
  display: flex;
  justify-content: space-between;
  height: 3.75rem;
  padding: 0 7.5rem !important;
}

.navHeaderLogoWrapper {
  display: flex;
  align-items: center;
}

.navHeaderLogoWrapper > a {
  height: fit-content;
}

.navHeaderLogo {
  width: 7rem;
}

.navbar-brand .logo {
  width: 3.5rem;
  height: 3.5rem;
}

.nav-link.active {
  color: var(--white) !important;
}

.navbar-collapse {
  justify-content: flex-end;
}

.login.nav-link,
.signUpLink.nav-link {
  display: flex;
  align-items: center;
  color: var(--white);
}

.login.nav-link:hover,
.signUpLink.nav-link:hover {
  color: var(--white);
  filter: brightness(0.9);
}

.padlockLogin {
  margin-right: 0.875rem;
  width: 1rem;
  height: 1.25rem;
}

.signUpWrap {
  margin-right: 0.75rem;
  width: 0.94rem;
  height: 1.15rem;
}

.signUpArrow {
  width: 0.875rem;
  height: 0.5rem;
  position: absolute;
  top: 44.8%;
  left: 3%;
}

.signUpLink {
  position: relative;
}

.login.nav-link {
  margin-right: 5.9rem;
}

.navHeader-signup {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--foregroundBlue);
  background-color: var(--orangeButton);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-family: "regular";
  font-weight: 500 !important;
  font: medium !important;
  text-decoration: none;
  border-radius: 0.5rem;
  margin-right: 3rem;
  border: 2px solid transparent;
}

.navHeader-signup:hover {
  border: 2px solid var(--foregroundBlue);
  color: var(--foregroundBlue);
  transition: all .3s ease-in-out;
}

.navHeader-signup:active {
  border: 3px solid var(--foregroundBlue);

}

.navHeader-dropDown,
.navHeaderLanguageDropDown {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  color: black;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(0, 100%);
  z-index: 10;
  min-width: 200px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.navHeaderLanguageDropDown {
  min-width: 150px !important;
  left: 0 !important;
}

.langSelector {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  color: black;
  position: absolute;
  bottom: 0;
  right: 170px;
  transform: translate(0, 100%);
  z-index: 10;
  min-width: 130px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.navHeader-dropDownWrapper {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
}
/*  */

.navHeaderLanguageDropDown > div,
.navHeader-dropDown > a,
.navHeader-dropDown-div,
.navHeader-dropDown-subDiv {
  font-weight: 400;
  padding: 0.75rem 1.25rem;
  font-family: Arial, Helvetica, sans-serif !important;
  color: var(--black);
  text-decoration: none !important;
  border-bottom: solid rgba(0, 0, 0, 0.15) !important;
  cursor: pointer !important;
}
.langSelector > div {
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif !important;
  padding: 0.75rem 1.25rem;
  color: var(--black);
  text-decoration: none !important;
  border-bottom: solid rgba(0, 0, 0, 0.15) !important;
  cursor: pointer !important;
}
.navHeader-dropDown-login {
  text-decoration: none;
  display: none;
  color: var(--black);
  width: 100%;
}

.navHeader-dropDown-login:hover {
  color: var(--black);
 
  transition: all .3s ease-in-out;
}

.navHeader-dropDown-subDiv {
  padding-left: 4rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.navHeaderLanguageDropDown > div {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.navHeader-dropDown-header {
  display: none;
}

.navHeaderLanguageDropDown > div:hover,
.navHeader-dropDown > a:hover,
.navHeader-dropDown-div:hover,
.navHeader-dropDown-subDiv:hover {
  background-color: rgba(177, 219, 252, 0.207) !important;
}
.langSelector > div:hover {
  background-color: rgba(177, 219, 252, 0.207) !important;
}

.navHeader-sandwhich {
  cursor: pointer;
}

.dropDownHidden {
  display: none;
}

.mobile-language {
  height: 0px;
  overflow: hidden;
}

.mobile-language.expandLanguageMenu {
  animation: expandLanguageMenu 0.5s linear;
  height: auto;
}

.mobile-language.shrinkLanguageMenu {
  height: 0px;
  animation: shrinkLanguageMenu 0.5s linear;
  animation-delay: 0ms;
}

.navHeaderLanguageWrapper {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-right: 3rem;
  height: 100%;
  cursor: pointer;
  position: relative;
}

.landingHeaderFlag {
  width: 24px;
  height: 16px;
}

@media (max-width: 1200px) {
  .login.nav-link {
    margin-right: 2.38rem;
  }
  #landingHeader .header {
    padding: 0 5rem !important;
  }
}

@media (max-width: 1024px) {
  .navHeader-signup {
    display: none;
  }

  #landingHeader .header {
    padding: 0 2rem !important;
  }

  .navHeader-dropDown-login {
    display: block;
  }
}

@media (max-width: 992px) {
  .login.nav-link {
    margin-right: 1.88rem;
  }

  #landingHeader .header {
    display: flex;
    justify-content: space-between;
    height: 3.75rem;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
  }
}

@media (max-width: 768px) {
  .navHeader-dropDownWrapper {
    position: static;
  }

  .navHeaderLanguageWrapper {
    display: none;
  }

  #landingHeader .header {
    padding: 0 1.25rem !important;
  }

  .navHeader-dropDown {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    transform: translateY(-100%);
  }

  .navHeader-dropDown-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem 0.5rem;
    border-bottom: 2px black solid;
    align-items: center;
  }

  .navHeader-dropDown-header-exit {
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
  }

  .dropDownHidden {
    display: flex;
  }

  .navHeader-dropDown.mobileHidden {
    display: flex;
    transform: translateX(-100%);
    animation: closeMenu 0.5s linear;
  }

  .navHeader-dropDown.mobileShown {
    animation: showMenu 0.5s linear;
    transform: translateY(0%);
  }

  /* .navHeader-dropDown:not(.mobileHidden) {
    animation: showMenu 0.5s linear;
  } */
}

@keyframes showMenu {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes closeMenu {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes expandLanguageMenu {
  from {
    height: 0px;
  }

  to {
    height: 100px;
  }
}

@keyframes shrinkLanguageMenu {
  from {
    height: 100px;
  }

  to {
    height: 0px;
  }
}

@media (max-width: 576px) {
  .navbar-brand {
    transform: translateX(3.4rem);
  }
}

@media (max-width: 476px) {
  .navbar-brand {
    transform: translateX(1.4rem);
  }
}

@media (max-width: 376px) {
  .navbar-brand {
    transform: translateX(-0.8rem);
  }
}
