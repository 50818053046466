.map_content .leaflet-container {
  /* height: 90vh; */
  background: #bddab1 !important;

  width: 100% !important;
  height: calc(100% - 90px) !important;
  position: fixed;
}
.map__nodata {
  height: calc(100% - 90px) !important;
  display: flex;
  position: absolute;
  top: 90px !important;
  color: white;
  width: 100%;
  justify-content: center;
  align-items: center;
  opacity: 1;
  z-index: 999;
  background: rgba(0, 0, 0, 0.3);
}
