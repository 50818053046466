.glanceContainer {
  padding: 0;
  width: 100%;
}

.glanceRowContainer {
  /* padding: 4rem 0 0 0; */
  padding: 60px 7.5rem 0;
  justify-content: center;
}

.glaceRow {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 1.5rem;
}

.glaceFooter {
  min-width: 900px;
  max-width: 1050px;
  width: 65%;
  padding: 0;
  text-align: center;
}

.glaceFooter-title {
  font-size: 2rem;
  color: var(--primaryBlue);
  font-weight: 600;
}

.glaceFooter-text {
  font-size: 1rem;
  padding: 0.5rem 0 1rem;
  color: var(--primaryBlue);
}

.glanceFooter-btnWrapper {
  display: flex;
  justify-content: center;
  padding: 1rem 0rem;
  gap: 1rem;
}



@media (max-width: 1200px) {
  .glaceRow {
    min-width: 850px;
    max-width: 1050px;
    width: 65%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .glanceCol-left {
    padding: 0 0 0 1rem;
  }

  .glaceCol-right {
    padding: 0 1rem 0 0;
  }

  .glaceFooter {
    min-width: 850px;
    max-width: 1050px;
    width: 65%;
    padding: 0;
    text-align: left;
  }
}

@media (max-width: 1024px) {
  .glaceRow {
    min-width: 0;
    max-width: none;
    width: 100%;
    margin: 0;
    padding: 0 2rem;
  }

  .glaceFooter {
    min-width: 0;
    max-width: 2050px;
    width: 100%;
    padding: 0 2rem;
  }

  .glanceRowContainer {
    padding: 2rem 0 0 0;
    justify-content: center;
    margin: 0;
  }
}

@media (max-width: 768px) {
  .glaceRow {
    grid-template-columns: 1fr;
    padding: 0 1.25rem;
  }

  .glanceCol-left {
    padding: 0;
  }

  .glaceCol-right {
    padding: 0;
  }

  .glaceFooter {
    padding: 0 1.25rem;
  }

  .glanceFooter-btnWrapper {
    justify-content: center;
    flex-direction: row-reverse;
    align-items: center;
    gap: 1rem;
  }

  .glanceRowContainer {
    margin: 0;
  }
}

@media (max-width: 576px) {
  .statisticsRow {
    flex-direction: column;
    gap: 2rem;
  }
}

@media (max-width: 450px) {
  .glanceFooter-btnWrapper {
    flex-direction: column-reverse;
    align-items: center;
    gap: 0.5rem;
  }

  .glanceFooter-btnWrapper button {
    width: 100%;
  }
}
