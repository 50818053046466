#getInvolved {
  padding-top: 60px;
}

.getInvolved {
  padding: 0px 7.5rem 0rem;
}

.getInvolvedTitle {
  padding: 0;
  font-size: 2.5rem;
  font-weight: 700;
}

.getInvolvedCardWrapper {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

@media (max-width: 1200px) {
  .getInvolved {
    padding: 0rem 5rem 0rem;
  }
}

@media (max-width: 1024px) {
  .getInvolved {
    padding: 0rem 2rem 0rem;
  }

  #getInvolved {
    padding-top: 40px;
  }
}

@media (max-width: 768px) {
  .getInvolved {
    padding: 0rem 1.25rem;
  }

  .getInvolvedTitle {
    font-size: 2rem;
    padding-bottom: 1rem;
  }
}

@media (max-width: 767px) {
  .getInvolvedCardWrapper {
    flex-direction: column;
    gap: 1rem;
  }

  .getInvolvedCardWrapper {
    display: flex;
    justify-content: space-between;
    gap: 2.5rem;
  }

  .getInvolvedTitle {
    font-size: 1.75rem;
    padding-bottom: 1rem;
  }

  .getInvolved {
    padding: 20px 1.25rem;
  }
}
