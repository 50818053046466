.boxCard {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.313rem;
  background: var(--communityBlue);
  color: var(--white);
  margin-bottom: 2rem;
  width: 100% !important;
  /* gap: 1rem; */
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;

  flex-direction: column;
  padding: 1rem 0;
}

.title {
  /* font-size: 3rem; */
  font-size: 56px;
  font-weight: 600;
  line-height: 68px;
  /* line-height: 4.25rem; */
  color: var(--white);
  letter-spacing: -2%;
}

.text {
  font-size: 28px;
  /* font-size: 2rem; */
  line-height: 2.25rem;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .title {
    font-size: 2.5rem;
  }

  .text {
    font-size: 1.5rem;
  }
}

@media (max-width: 1024px) {
  .title {
    font-size: 2.5rem;
  }

  .text {
    font-size: 1.5rem;
  }
}

@media (max-width: 576px) {
  .boxCard {
    flex-direction: column;
    padding: 1.5rem 0;
    gap: 0rem;
  }

  .title {
    line-height: 1.2;
  }
}
