.landingFooterConnect > a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sirenLandingFooterImage {
  position: relative;
  z-index: 2;
}

.sirenLandingFooterImage {
  height: 2.3rem;
  width: 2.3rem;
  padding: 0.1rem;
}

.sirenLandingFooterImage.sirenLandingFbIcon {
  padding: 0;
}

.sirenLandingFooterImage.sirenImagePadding {
  padding: 0.3rem;
}

.sirenLandingFooterImage.sirenInstaImage {
  padding: 0.2rem;
}

.sirenLandingFooterImage:hover + .landingIconOutline {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  opacity: 70%;
}

.sirenLandingFooterImage:active + .landingIconOutline {
  opacity: 100%;
}

.facebookIconOutline {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  position: absolute;
}

.instagramOutline {
  width: 28px;
  height: 28px;
  border-radius: 23%;
  position: absolute;
}

.youtubeOutline {
  width: 32px;
  height: 24px;
  border-radius: 26%;
  position: absolute;
}

.linkedinOutline {
  width: 27px;
  height: 27px;
  border-radius: 15%;
  position: absolute;
}

.twitterOutline {
  position: absolute;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 53%;
  left: 43%;
  transform: translate(-50%, -50%);
  opacity: 0%;
  rotate: 7deg;
}

.sirenLandingFooterImage:hover + .twitterOutline {
  opacity: 70%;
}

.sirenLandingFooterImage:active + .twitterOutline {
  opacity: 100%;
}

.ConnectWithUs {
  padding-bottom: 0.8rem !important;
}

@media (max-width: 450px) {
  .landingFooterConnect {
    width: 100%;
    justify-content: space-between;
  }

  .landingFooterConnect > a {
    width: 15%;
    aspect-ratio: 1/1;
  }

  .sirenLandingFooterImage {
    width: 100%;
    height: 100%;
  }

  .sirenLandingFooterImage.sirenImagePadding {
    padding: 0.5rem;
  }

  .sirenLandingFooterImage.sirenInstaImage {
    padding: 0.4rem;
  }

  .sirenLandingFooterImage {
    height: 3rem;
    width: 3rem;
    padding: 0.3rem;
  }

  .sirenLandingFooterImage.sirenLandingFbIcon {
    padding: 0.2rem;
  }
}
