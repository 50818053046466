.loader {
  background: conic-gradient(transparent, #00255a);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: load 2s linear infinite;
}
.loader::before {
  content: "";

  width: 73%;
  height: 73%;
  border-radius: 50%;
  background-color: white;
}
.footer-information{
  display: flex;
  flex-wrap: nowrap !important;
  width: max-content !important;
  padding-left: 20px;
}
.table_hidden_footer_info{
  padding-left: 5px;
}
@media only screen and (max-width: 950px) {
  .table_hidden_footer_info{
   display: none ;
  }
}
@media only screen and (max-width: 760px) {
  .footer_totalRow{
   display: none ;
  }
}
@keyframes load {
  100% {
    transform: rotate(360deg);
  }
}
.search__input {
  width: 100% !important;
}
