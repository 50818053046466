.custom-success-alert {
  background: #ebffe8;
  height: 64px;
  opacity: 1;
  z-index: 8888 !important;

  color: #138101;
  position: absolute !important;
  display: flex;
  align-items: center;
  width: 100vw;
}
.custom-error {
  height: 64px;
  opacity: 1;

  z-index: 1;
  font-size: 16px;
  color: crimson;
  position: absolute;
  background: #f8d7da;
  display: flex;
  align-items: center;
  width: 100vw;
}
