body {
  font-family: "regular";
  overflow-x: hidden;
}

.regular {
  font-family: "regular";
}

.logo {
  width: 105px;
  height: 34.27px;
  position: relative;
  top: 5px;
}

.obs-cont-date {
  position: relative;
  top: 2px;
}

.logos {
  width: 130px;
  /* height: 34.27px; */
}

.f-black {
  font-family: "black";
}

.f-bold {
  font-family: "bold";
}

.press,
.login,
.setsend,
.setnew {
  background-color: #073b60;
}
.press:hover,
.login:hover,
.setsend:hover,
.setnew:hover {
  background-color: white;
  color: #073b60;
  border: 1px solid #073b60;
}

.lien {
  color: #073b60;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

input,
.textarea {
  padding: 8px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: 1px solid #b3b3b3;
}

.noDeco {
  text-decoration: none;
}

.h-70 {
  height: 80px !important;
}

.cl-gr {
  color: #7c7c7c;
}

.eye-reveal {
  position: absolute;
  right: 10px;
  top: 33px;
}
.eye-close {
  position: absolute;
  right: 11px;
  top: 35px;
}

.fs-11 {
  font-size: 11px;
}

.fs-16 {
  font-size: 16px;
}

.bg-cl-bl,
.btn-primary {
  background-color: #105388 !important;
  /* background-color: #06518b !important; */
}

.cl-bl {
  color: #073b60 !important;
}

.header {
  background-color: #096dbb;
  padding-top: 2px;
  padding-bottom: 2px;
  height: 50px;
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.main-list-view {
  margin-top: 90px;
}

.header-list-view {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0;
  z-index: 100;
}



.error {
  color: red;
}

.forgotpass {
  position: absolute;
  right: 0px;
  bottom: 1px;
}

.head02 {
  position: relative;
  z-index: 2 !important;
  height: 40px;
  left: -0.25px;
  /* top: 60px; */
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.menu-button-top {
  color: #ffffff;
  white-space: normal;
  word-break: break-word;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.element-menu-top {
  top: 1px;
  color: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  position: relative;
  bottom: 2px;
  transition: all;
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  font-size: 14px;
}

.element-menu-top:hover {
  background-color: #0e4773;
  color: #ffffff;
  transition-duration: 0.5s;
}

.element-menu-top .ico {
  width: 20px;
}

.pro {
  width: 25px !important;
  margin: 0px !important;
}

.obs-list-item {
  padding-left: 70px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.menu-button-bas {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.borderright {
  border-right: 1px solid #c5c5c5;
}

.menelem {
  transition: all;
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
}

.menelem:hover {
  background-color: #ebeef1;
  transition-duration: 0.5s;
}

.filtre {
  width: 20px;
  position: relative;
  bottom: 2px;
}

.inpttop {
  height: 30px;
}

@media (min-width: 873px) {
  .inpttop {
    width: 160px;
  }
}
@media only screen and (max-width: 873px) {
  .header {
    padding-bottom: 20px !important;
    padding-top: 5px;
  }
}

.btn-clr,
.btn-sort,
.btn-sear,
.btn__sort {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: #0e4773 solid 1px;
  padding: 2px;
  margin-left: 5px;
  outline: none !important;
  font-size: 14px;
  padding-left: 7px;
  padding-right: 7px;
}

.btn__sort {
  width: 65px !important;
  height: 27px !important;
}

.btn-clr:hover,
.btn-sort:hover,
.btn-sear:hover,
.btn__sort:hover {
  border: #0e4773 solid 1px;
  background-color: #ebeef1;
}

.positxt {
  position: relative;
  top: 2px;
}

.shorsearch,
.stafdate,
.bpro,
.act-cnt {
  position: relative;
}

.content-sort,
.cont-date,
.content-projet,
.content-obs,
.content-valid,
.cont-action {
  background-color: #ffffff;
  position: absolute;
  /* height: 100vh; */
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: auto;
}
.content-search {
  margin-top: 0px !important;
  background-color: #ffffff;
  position: absolute;
  left: 20px ;
  width: 255px !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.z-10 {
  z-index: 10 !important;
}

.z-9 {
  z-index: 10 !important;
}

.z-8 {
  z-index: 8 !important;
}

.cont-obs {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  background-color: #ffffff;
  width: 280px !important;
  top: 2px;
  /* left: 0px; */
}

.content-obs {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding-top: 5px;
}

.observation-checked,
.observation-type {
  top: 20px;
  left: 20px;
}

.obsckd {
  padding: 5px 40px 5px 15px;
  position: relative;
  background: #ffffff;
  border: 1px solid #000000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  height: 32px;
  font-size: 14px;
}

.obstype {
  padding: 5px 15px 5px 15px;
  position: relative;
  border-radius: 16px;
  height: 32px;
  font-size: 12px;
}

.type-flash {
  background-color: #00d4e2;
  color: #252525;
}

.type-animal {
  background-color: rgb(19, 19, 19);
  color: white;
}

.type-signs {
  background-color: #3a8ac9;
  color: white;
}

.type-threat {
  background-color: #e70909;
  color: white;
}

.type-crowls {
  background-color: #c8f0ff;
  color: #252525;
}

.type-nest {
  background-color: #22a6c1;
  color: white;
}

.type-female {
  background-color: #20638d;
  color: white;
}

.btn-legend {
  bottom: 20px;
  /* left: 20px !important; */
  background: #ffffff;
  border: 1px solid #000000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  height: 32px;
  font-size: 14px;
  padding: 4px 12px;
  transition-duration: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.legend {
  bottom: 20px;
  /* left: 20px; */
  background: #ffffff;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  /* height:490px; */
  width: 320px;
  padding-bottom: 5px;
}

.btn-zoom {
  bottom: 20px;
  right: 20px;
  background: #ffffff;
  border: 1px solid #000000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  height: 32px;
  font-size: 14px;
  width: 92px;
  height: 32px;
  overflow: hidden;
}

.btn-obs-close {
  position: absolute;
  right: 10px;
  bottom: 7px;
  /* content: url("assets/elements/images/icon/close2.svg"); */
  cursor: pointer;
}

.cont-check-action {
  position: absolute;
  right: 10px;
}

.men-action-item {
  position: relative;
}

.check-select {
  position: relative;
  right: 3px;
}

.obs-close {
  position: absolute;
  top: 27px;
  z-index: 100;
}

.zoom-map {
  height: 100%;
  width: 45px;
  font-size: 24px;
  color: #444444;
}

.third-level {
  margin-left: 20px;
}

.zoom-map:hover,
.btn-legend:hover {
  background-color: #ebeef1;
}

.title-men {
  font-size: 17px;
  font-weight: bold;
}

.sort-list,
.projet-list,
.cont-obs {
  color: #073b60;
}

.sort-list a,
.projet-list a,
.cont-obs a,
.cont-action a {
  text-decoration: none;
  padding: 12px 24px !important;
  border-bottom: solid rgba(0, 0, 0, 0.15);
  color: #073b60;
  cursor: pointer;
}

.cont-action {
  width: 170px !important;
}

.sort-list a:hover,
.projet-list a:hover,
.cont-obs a:hover,
.cont-action a:hover {
  background-color: rgba(177, 219, 252, 0.207);
}

.cont-date {
  min-height: 368px;
  padding-bottom: 40px;
}

.obs-item {
  height: 114px;
  /* background: #ffffff; */
  border: 0.5px solid #595959;
  overflow: hidden;

  border-radius: 5px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}


.more {
  top: 7px;
  right: 10px;
}

.obs-date {
  font-size: 10px;
  color: #595959;


}

.obs-type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px 12px;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
}

.obs-color-type-01 {
  background: #22a6c1;
  color: white;
  font-size: 12px;
}

.divier-obs {
  height: 5px;
}

.obs-name {
  font-size: 15px;
}

iframe {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
}

.map {
  z-index: 0;
}

.btn-add {
  width: 46px !important;
  height: 46px !important;
  border-radius: 56px;
  -webkit-border-radius: 56px;
  -moz-border-radius: 56px;
  -ms-border-radius: 56px;
  -o-border-radius: 56px;
  display: flex;
  position: absolute;
  bottom: 60px !important;
  /* left: 20px; */

  /* z-index: 2 !important; */

  background-color: #096dbb;
  /* background-color: #073B60; */
  color: #ffffff;
  font-size: 25px;
  font-weight: 700;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  /* transition-duration: 0.4s; */
}

.btn-add:hover {
  background-color: #025a99;
}

.alignCC {
  align-items: center;
  justify-content: center;
}

.dropdown-toggle::after {
  display: none;
}

.border-date {
  border: 1px solid #9ea3a5;
  padding: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.datepicker-inline {
  width: auto !important;
}

.day {
  width: 33px !important;
  height: 33px !important;
}

.modal-header .btn-close {
  color: white !important;
}

.modal-footer {
  justify-content: center;
}

.photo-det-obs {
  border-left: 1px solid rgb(0 0 0 / 15%);
  overflow: none !important;
}

.detail-obs {
  border: 1px solid rgba(0, 0, 0, 0.15);
  overflow-x: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.obs-title {
  background-color: rgb(197, 216, 255);
}
.btn-action-off,
.btn-action-on {
  border-radius: 8px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding: 4px;
  margin-left: 5px;
  font-size: 14px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.btn-action-off {
  background: #073b60;
  border: none !important;
  opacity: 0.3;
  cursor: not-allowed !important;
  color: #ffffff !important;
}

.btn-action-on {
  border: none !important;
  background: #0e436c !important;
  color: white !important;
}
.btn-action-on:hover {
  background: #0e4773 !important;
  color: white !important;
}

.fml {
  color: white;
  background-color: #073b60;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.elm-obs {
  color: #135d95;
}

.obs-gray {
  background-color: #e3e3e3;
}

.obs-white {
  background-color: white;
}

@media (max-width: 1285px) {
  .obs-txt {
    display: none;
  }
  .pxh-3 {
    padding-right: 5px;
  }
  .header {

    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media (max-width: 1166px) {
  .filter-txt {
    display: none;
  }
}

@media (max-width: 985px) {
  .shark-txt {
    display: none;
  }
}

@media (max-width: 872px) {
  .calander-txt {
    display: none;
  }
}

.bsearch {
  height: 27px !important;
  width: 27px !important;
}

.bsearch img {
  position: relative;
  bottom: 1px;
  right: 2px;
}
.bsearch2 {
  height: 26px;
}

.bsearch2 img {
  position: relative;
  bottom: 1px;
}

.legend-close {
  position: absolute;
  right: 13px;
  top: 10px;
}

.thead,
.tr {
  padding: 5px !important;
}

.pagination {
  font-size: 13px;
  position: fixed;
  bottom: 0px;
  background-color: #a4a4a4;
  height: 50px;
  width: 100%;

  padding-left: 50px;
  padding-right: 50px;

  display: flex;
  overflow: auto;
}

.selected-page {
  text-decoration: underline;
  font-family: "bold";
}

.souligne {
  text-decoration: underline;
}

.tebbais {
  position: relative;
  top: 4px;
}

.arrow-tab {
  position: absolute;
  right: 5px;
}

.list-view-tab tbody td {
  text-overflow: "…";
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.checkvl {
  position: relative;
  top: 6px;
  left: 3px;
}

.mini {
  top: 0px;
}

.morephoto {
  right: 1px;
}

.title-tab,
.list-view-tab tbody td {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.title-tab {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  /* border-bottom: 1px solid #808080; */
}

.tab-info {
  font-size: 14px;
}

.z-11 {
  z-index: 11 !important;
}

.lst-table {
  table-layout: fixed;
  width: 100%;
}

.table-wrapper {
  /* max-height: 100px; */
  overflow: auto;
  display: inline-block;
}

.tbhead {
  background-color: #dbecf7;
  border: none;
  font-size: 14px;
  z-index: 9 !important;
  position: relative;
}

.scrollH {
  z-index: 9 !important;
  position: relative;
}

tbody tr:nth-child(odd) {
  background-color: #fcfcfc;
}

tbody tr:nth-child(even) {
  background-color: #ffffff;
}

tbody tr:nth-child(odd) > th:nth-child(1) {
  background-color: #fcfcfc !important;
  z-index: 8 !important;
  position: relative;
}
tbody tr:nth-child(odd) > td:nth-child(2) {
  background-color: #fcfcfc !important;
  z-index: 8 !important;
  position: relative;
  /* border-right: 2px solid #252525; */
}

tbody tr:nth-child(even) > th:nth-child(1) {
  background-color: #ffffff !important;
  z-index: 8 !important;
  position: relative;
}
tbody tr:nth-child(even) > td:nth-child(2) {
  background-color: #ffffff !important;
  z-index: 8 !important;
  position: relative;
  /* border-right: 2px solid #252525; */
}

thead tr th:nth-child(1) {
  background-color: #dbecf7 !important;
  z-index: 9 !important;
}
thead tr th:nth-child(2) {
  background-color: #dbecf7 !important;
  z-index: 9 !important;
  /* border-right: 2px solid #252525; */
}
.content-obs {
  box-shadow: rgba(0, 0, 0, 0.753) 0px 22px 70px 4px !important;
  margin-top: 5px !important;
}
