.signUpBtn {
  background: var(--orangeButton);
  padding: 0.4rem 2.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  color: var(--foregroundBlue);
  text-decoration: none;
  position: relative;
}

.signUpBtn:hover {
  color: var(--foregroundBlue);
  transition: all 0.3s ease-in-out;
}

.signUpBtnRotate {
  transform: rotate(270deg);
}
.signUpBtnRotate:hover{
  transition: all 0.3s ease-in-out;
}

.signUpBtn::after {
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  background-color: transparent;
  content: "";
  z-index: 2;
  border-radius: 8px;
}

.signUpBtn:hover::after {
  border: solid 2px var(--foregroundBlue);
 
}

.signUpBtn:hover {
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.signUpBtn:active:after {
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border: solid 4px var(--foregroundBlue);
  
}

@media (max-width: 450px) {
  .signUpBtn {
    width: 100%;
  }
}
