.latestNews {
  display: flex;
  flex-direction: column;
}

.latestNews-title {
  background: var(--communityBlue);
  padding: 0rem 7.5rem;
  color: #ffffff;
  font-size: 3rem;
  height: 100%;
  line-height: 4.25rem;
  margin-bottom: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 170px;
  position: relative;
}

.latestNews-title::after {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  background-color: var(--communityBlue);
  z-index: -1;
  content: "";
  bottom: 0;
  transform: translateY(100%);
}

.latestNewsSlider {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  gap: 2rem;
}

/* .latestNewsSlider > div {
  height: max-content;
} */

.latestNewsCardRow {
  padding: 0rem 7.5rem;
}

.latestNewsBtnWrapper {
  display: flex;
  justify-content: center;
  padding: 4rem 7.5rem 2rem;
}

.latestNewsFiller {
  background-color: #ffffff;
}

@media (max-width: 1200px) {
  .latestNews-title {
    font-size: 3rem;
    padding: 0rem 5rem;
  }

  .latestNewsCardRow {
    padding: 0rem 5rem;
  }

  .latestNewsBtnWrapper {
    padding: 4rem 5rem 2rem;
  }
}

@media (max-width: 1024px) {
  .latestNewsCardRow {
    padding: 0rem 0 0 1.5rem;
  }

  .latestNewsSlider {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 2rem 0.5rem 0.5rem;
    overflow-x: scroll;
    scroll-behavior: smooth;
    gap: 2rem;
  }

  .latestNewsSlider::-webkit-scrollbar {
    display: none;
  }

  .latestNewsSlider {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .latestNews-title {
    font-size: 2.5rem;
    padding: 0rem 0 0 2rem;
    height: 140px;
  }

  .latestNewsBtnWrapper {
    padding: 3rem 2rem 2rem;
  }
}

@media (max-width: 768px) {
  .latestNews-title {
    padding: 0rem 0 0 1.25rem;
    font-size: 2.25rem;
  }

  .latestNewsCardRow {
    padding: 0rem 0 0 0.75rem;
  }

  .latestNewsBtnWrapper {
    padding: 3rem 1.25rem 2rem;
  }

  .latestNewsSlider {
    gap: 2rem;
  }

  .latestNewsSlider {
    padding: 0 1.25rem 0.5rem 0.5rem;
  }
}

@media (max-width: 500px) {
  .latestNewsBtnWrapper {
    padding: 2.5rem 1.25rem 2rem;
  }
}

@media (max-width: 350px) {
  .latestNewsSlider {
    gap: 1rem;
  }
}
