.admin-menu {
  background-color: rgba(242, 242, 242, 1);
  width: 264px;
  padding-top: 46px;
}

.admin-menu-item,
.admin-menu-btn-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 40px 0px 48px;

  height: 64px;
}

.curpoint {
  cursor: pointer;
}

.admin-menu-item.actived {
  background: #e0f1fe;
  color: #075796;
  font-weight: 600;
}

.admin-menu-item:hover {
  background: #e0f1fe;
}

.btn-logout {
  border: 2px solid #042b4a !important;
  padding: 10px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-left: 0 !important;
  min-width: 100px !important;
}

@media screen and (max-width: 580px) {
  .admin-menu {
 
    width: 200px !important;

  }
}