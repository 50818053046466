::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: rgb(205, 203, 203); /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: grey; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid rgb(205, 203, 203); /* creates padding around scroll thumb */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
}
/* name all colors variable for titles, background, button, text */
:root {
  /* here 1rem = 10px; Originally 1rem = 16px = 100% */
  /* font-size: 62.5%; */

  --black: #000000;
  --white: #ffffff;
  --whiteSmoke: #f5f5f5;
  --primaryBlue: #073b60;
  --secondaryBlue: #00253d;
  --foregroundBlue: #08253b;
  --seaBlue: #096dbb;
  --communityBlue: #075796;
  --featuresBlue: #00589b;
  --orangeButton: #ff9800;
  --magentaButton: #c21bd5;
  --fuchsiaButton: #e24ef3;
  --turquoiseButton: #00d4e2;
  --ellipseBackground: #d9d9d9;
  --ellipseBackgroundActive: #848484;
  --cardBackground: #e5f4ff;
  --lime: #c0e723;
  --softGreen: #3abac9;
}

body,
input,
button {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
}
.bgPrimary {
  background-color: #096dbb !important;
}
/* .mapContainer{
  width: 100%;
  height: 82vh;

} */
.gm-ui-hover-effect {
  display: none !important;
}
.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
  max-width: 300px !important;
}
.infoWindowBtn {
  float: right;
  background: none;
  outline: none;
}
.leaflet-popup-content-wrapper {
  background-color: white !important;

  height: fit-content !important;

  width: 400px !important;
}
.leaflet-popup-content {
  margin-left: 0px !important;
  padding-left: 0px !important;
  width: 390px !important;
}
.transition {
  left: -400px;
  transform: translateX(0%);
  transition: 0.8s;
  pointer-events: auto;
}
.transition2 {
  right: 0;
  transform: translateX(0%);
  transition: 0.8s;
  pointer-events: auto;
}
.ant-form-item-control-input-content {
  border: none !important;
}
.ant-col .ant-form-item-control .css-dev-only-do-not-override-10ed4xt {
  border: none !important;
}
.ant-date-picker {
  display: flex;
  height: 35px !important;
}
.ant-picker-suffix {
  display: none !important;
}
.toggle-arrow {
  margin-right: 5px;
}
@media only screen and (max-width: 1007px) {
  .positxt {
    display: none;
  }
  .btn-sear {
    width: 20px !important;
  }

  .female .tableImage {
    color: transparent;
    height: 150px !important;
    width: 150px !important;
  }
  .female .imageWrapper {
    height: 150px !important;
    width: 150px !important;
  }
}
@media only screen and (max-width: 1210px) {
  .positxt {
    font-size: 0.8rem;
  }
  .btn-sear {
    font-size: 0.7rem;
    padding: 1px;
    width: 35px !important;
  }
}

.crud_select .crud-input {
  text-align: center !important;
}

.rgt-columns-manager-wrapper {
  display: none !important;
}
.rgt-footer {
  background: #cac7c7 !important;
}
.rgt-row-even {
  background: #efefef !important;
}
.list__tables .rgt-cell-id {
  border-right: 1px solid grey !important;
}
.rgt-cell-header {
  color: #111315;
  width: 100% !important;
  padding: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  background: #dbecf7 !important;
  opacity: 1;
}
.rgt-cell-header-inner {
  background: transparent !important;
  background: none !important;
}
.rgt-footer-pagination-button {
  background: #073b60 !important;
}
.rgt-footer-pagination-button:hover {
  background: #075796 !important;
  transition: 3s ease-in-out !important;
}
.rgt-footer-pagination-button.rgt-disabled-button {
  background: #677f91dc !important;
}
#singleImageModal {
  background: red !important;
}

/* set coords css */

.coords__wrapper {
  width: 102%;
  position: relative;
  height: 30px;

  font-size: 0.8rem;
  border: 0.5px solid #b3b3b3;
  border-radius: 5px;
  cursor: pointer;
}
.coords__container {
  display: flex;

  padding: 5px;
  width: 102%;

  /* width: 300px; */
}


.coords__marker {
  position: absolute;
  top: 0;
  right: 5px;
  width: 11px;
}
.coords__wrapper__content {
  position: relative;
}
.coords__popup__wrapper {
  height: 100px;
  width: 256px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  position: absolute;
  border-radius: 5px;
}

.coords__inputs__container {
  display: flex;
  padding: 10px;
}
.coords__inputs input {
  width: 90px;
  font-size: 0.7rem;
  height: 24px;
  border-radius: 5px;
  outline: none;
}
.coords__inputs label {
  font-size: 11px;
  color: #595959;
}
.coords__btn__coords {
  display: flex;
}
.coords__btn__coords {
  display: flex;
  padding: 0px 10px;
  justify-content: space-between;
}
.coords__btn__coords button {
  display: block;
  width: 90px;
  border-radius: 5px;
  height: 25px;
  outline: none;
  font-size: .8rem;
  border: 1px solid #042b4a;
  color: #042b4a;
  background: white;
}
.coords__btn__coords button:hover {
  background: #b1dbfc;
  transition: 0.3s ease-in;
  color: #042b4a;
}
.coords__btn__coords button.save {
  background: #073b60;

  color: #fff;
}
.coords__btn__coords button.save:disabled {
  background: #dddddd;
  border: 0.5px solid #dddddd;
  color: white;
  cursor: not-allowed;
}
.coords__btn__coords button.save:disabled:hover {
  background: #dddddd;
  border: 0.5px solid #dddddd;
  color: white;
  cursor: not-allowed;
}
.coords__btn__coords button.save:hover {
  background: #075796;
  transition: 0.3s ease-in;
  color: #fff;
}

.no-image {
  width: 100%;
  height: 100%;
}
#no-image path {
  fill: rgba(128, 128, 128, 0.577);
}
#no-image path:hover {
  fill: rgba(128, 128, 128, 0.704);
  transition: 0.2s ease-in-out;
}
.mvp__modals {
  background: rgba(0, 0, 0, 0.6) !important;
  border-radius: 10px !important;
}


.modalImgBody {
  background: rgba(0, 0, 0, 0.502) !important;
}
.ant-notification-notice {
  width: 800px !important;
}
.close-modal-icon {
  font-size: 18px;
}
.close-modal-icon:hover {
  color: rgb(168, 168, 168);
  transition: 1s ease;
}
.btn-primary {
  outline: none !important;
}
.search__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}
.clock__icon {
  z-index: 9999 !important;
  /* color: red !important; */
}
.rgt-cell {
  cursor: default !important;
}

.modal__header {
  height: 36px !important;
  font-size: 0.8rem;
}

.modal__header__title {
  font-size: 1rem !important;
}

.modal-top {
  height: 225px;
  border-bottom: 1px solid #cccccc;
}
.female .modal-top {
  height: 140px;
  border-bottom: 1px solid #cccccc;
}
.female   .create_image_modal {
  height: 530px;
}

.modal-size {
  max-width: 602px !important;
  max-height: 664px !important;
}

.no__cords {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
  border-right: 0.5px solid #bbb8b8;
  flex-direction: column;
}

.modal-photo-btn {
  top: 223px;
}
.modal-photo-btn.disabled {
  top: 98px;
}


.female .modal-photo-btn {
  top: 108px !important;
}
.female .modal-size {
  max-width: 500px !important;
  max-height: 664px !important;
}

.modal__obs__details {
  font-size: 10px;
}

.modal__obs__details__row {
  border-bottom: 1px solid #cccccc;
  font-size: 13px;

  padding: 4px 0px;
}
.crud_select.crud-input {
  color: #073B60;
  font-weight: 400 !important;
}
.react-date-picker__calendar-button.react-date-picker__button{
  color: #073B60;
}
.ant-picker-input input{
  color: #073B60 !important;
  
}
.modal__title__value {
  color: #073B60;
font-weight: 400 !important;
  margin-top: 3px;
}


  .modal__title__value.details {
   
    font-size: 0.8rem !important;
    overflow: hidden;
    text-overflow: ellipsis !important;

    width: 50% !important;
    /* font-size: 0.7rem; */
    white-space: nowrap;
    display: inline-block;
    padding-top: 5px !important;
    -webkit-line-clamp: 3;
    line-clamp: 3;   
     -webkit-box-orient: vertical;
    overflow: hidden;
   

  }
  .modal__title__value.note {
   
    font-size: 0.8rem !important;
 

    width: 50% !important;
   
   

  }

  .modal__title__value.author {
    margin-top: 8px;
  }
  .modal__obs__details__row.projectName {
    border-bottom: 1px solid #cccccc;
    font-size: 12px;
    height: 40px;
    padding: 4px 0px;
  }
  .modal__obs__details__row.note {
    border-bottom: none !important;
    font-size: 12px;
    min-height: 40px !important;
    padding: 4px 0px;
  }
  .modal__detail__title {
    font-size: 12px !important;
    color: #595959;
    /* margin-top: 10px; */
    display: flex;
    align-items: center;
  }

.imageWrapper {
  height: 270px;
  width: 244px;
}

.tableImage {
  color: transparent;
  height: 270px;
  width: 244px;
}
.tableImage.details {
  height: 270px;
  width: 244px;
}
.time-picker-input {
  height: 28px !important;
  width: 100%;
}
.modal__header__title {
  font-size: 1rem !important;
  border-radius: 10px !important;
}
.create__modal .modal-content {
  border-radius: 15px !important;
}
.mvp__modals.female {
  background: rgba(0, 0, 0, 0.752) !important;
}
.female .modal-content {

  width: 500px !important;
  border-radius: 15px !important;
}
.female .tableImage {
  color: transparent;
  height: 200px;
  width: 200px;
}
.female .imageWrapper {
  height: 200px;
  width: 200px;
}
.view_modal .modal-content {
  border-radius: 15px 15px 15px 15px !important;
  /* background: transparent !important; */
}
.edit__observation .modal-content {
  border-radius: 15px !important;
}
.modal__header {
  height: 36px !important;

  border-radius: 10px 10px 0px 0px !important;
  font-size: 0.8rem;
}
.single__obs__id {
  font-size: 1rem;
}
.react-date-picker__wrapper {
  border-radius: 5px !important;
  width: 150px !important;
  height: 28px;
}
.female .react-date-picker__wrapper {
  border-radius: 5px !important;
  width: 120px !important;
  font-size: .6rem !important;
  height: 28px;
}
.nest .react-date-picker__wrapper {
  border-radius: 5px !important;
  width: 305px !important;

  z-index: 1 !important;
  position: relative !important;
  height: 28px;
  padding: 0px 5px !important;
}
.nest .react-date-picker__calendar.react-date-picker__calendar--open {
  position: absolute !important;
  right: 70% !important;
  z-index: 9999 !important;
}

/* 


*/

.mobile-action-btn {
  display: none;
}

.modal-footers {
  padding: 8px 15px;
  height: 55px;
  justify-content: flex-end;
}
.modal-footers .modal_obs__btn {
  margin: 0px 10px !important;
  padding: 3px 0px !important;
  width: 110px !important;
}
.modal-footers .modal_obs__btn.edit {
  width: 130px !important;
  font-size: 0.9rem;
}
.react-calendar{
  width: 270px !important;
  font-size: .7rem !important;
  height: 290px !important;
}
.coords__eclipe__text {
  width: 110px !important;

  white-space: nowrap;
  display: inline-block;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 1600px) {
  .nest .react-date-picker__calendar.react-date-picker__calendar--open {
    position: absolute !important;
    right: 70% !important;
    z-index: 9999 !important;
  }
  .create_image_modal {
    height: 570px;
  }
  .coords__eclipe__text {
    width: 70px !important;
    /* font-size: 0.7rem; */
    white-space: nowrap;
    display: inline-block;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis ;
  }
  .date__eclipe__text {
    width: 100px !important;

    white-space: nowrap;
    display: inline-block;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .obs__id {
    font-size: 1rem;
  }
  .author__eclipse__text {
    max-width: 200px !important;

    white-space: nowrap;
    display: inline-block;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .no__cords {
    font-size: 0.8rem;
    padding-top: 0px;
  }

 
  .react-calendar__tile .react-calendar__month-view__days__day{
    font-size: 0.7rem !important;
    width: 10px !important;
  }

  .react-calendar{
    width: 270px !important;
    font-size: .7rem !important;
    height: 290px !important;
  }
  .time-picker-input-container {
    width: 100% !important;
  }
  .time-picker-input {
    border: 0.5px solid #b3b3b3 !important;
    margin-left: 7px !important;
    font-size: 0.6rem;
    height: 25px !important;
    width: 100% !important;
  }
  .react-date-picker__inputGroup {
    font-size: 0.9em !important;

    width: 100% !important;

    padding: 0 !important;
  }
  .react-date-picker__calendar-button.react-date-picker__button {
    padding: 0px 1px !important;
    margin: 0 !important;
  }

  .react-date-picker__wrapper {
    border: 0.5px solid #b3b3b3 !important;
    height: 25px;
    font-size: 0.7rem !important;
    width: 150px !important;
    position: relative !important;
    z-index: 1 !important;  

  }


  /* set coords css */

  .coords__wrapper {
    width: 102%;
    position: relative;
    height: 25px;

    font-size: 0.7rem;
    border: 0.5px solid #b3b3b3;
    border-radius: 5px;
    cursor: pointer;
  }
  .coords__container {
    display: flex;

    padding: 5px;
    width: 102%;
    /* width: 300px; */
  }
  .coords__popup__wrapper {
    height: 100px;
    width: 256px;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
    position: absolute;
    border-radius: 5px;
  }

  .coords__inputs__container {
    display: flex;
    padding: 10px;
  }
  .coords__inputs input {
    width: 80px;
    font-size: 0.7rem;
    height: 24px;
    border-radius: 5px;
    outline: none;
  }
  .coords__btn__coords {
    display: flex;
    /* padding: 0px 10px; */
    justify-content: space-between;
  }
  .coords__btn__coords .save {
    margin-left: 6px;
  }
  .coords__btn__coords button {
    display: block;
    width: 80px;
    border-radius: 5px;
    height: 25px;
    margin: 0px 1px;
    outline: none;
    border: 1px solid #042b4a;
    color: #042b4a;
    background: white;
  }
  .coords__btn__coords button:hover {
    background: #b1dbfc;
    transition: 0.3s ease-in;
    color: #042b4a;
  }

  .crud_select,
  .crud-input,
  .crud_select_class {
    border: 0.5px solid #b3b3b3;
    height: 25px;
    font-size: 0.7rem;

    border-radius: 5px;
  }

  .error {
    font-size: 0.5rem;
  }
  .species-select-item select {
    width: 100% !important;
  }

  .image__upload__container {
    padding-left: 10px;
  }
  .imageWrapper {
    margin: 10px;
    color: transparent;
    height: 240px;
    width: 260px;
  }
  .tableImage.details {
    height: 240px;
    width: 260px;
  }
  .image__details__container {
    height: 200px;
    width: 200px;
  }


  .tableImage {
    color: transparent;
    height: 200px;
    width: 200px;
  }
}
@media only screen and (max-width: 766px) {
  .lat__container {
    width: 85px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .mobile-action-btn {
    display: block;
    width: 100%;
  }
  .modal-size {

    max-height: 664px !important;
  }
  
}

@media only screen and (max-width: 485px) {
  .nest .react-date-picker__wrapper {
    z-index: 1 !important;
    position: relative !important;
    border-radius: 5px !important;
    width: 192px !important;
    height: 28px;
    padding: 0px 5px !important;
  }
  .female .react-date-picker__wrapper {

    width: 90px !important;
    font-size: .6rem !important;
    height: 25px !important;
  }
  .auth_forms {
    padding: 0px 10px;
  }
  .react-date-picker__wrapper {
    font-size: 0.6rem !important;
    width: 78px !important;
  }
  .imageWrapper {
    height: 180px;
    width: 150px;
  }
  .mobile-action-btn {
    display: block;
  }
  .tableImage {
    color: transparent;
    height: 180px;
    width: 150px;
  }
  .tableImage.details {
    height: 180px;
    width: 150px;
  }
  .time-picker-input {
    height: 25px !important;
    width: 100% !important;
  }
  .react-date-picker__inputGroup {
    width: 100% !important;
  }
  .calender__picker {
    width: 50% !important;
  }
  .time-picker-input-container {
    width: 70% !important;
  }

  .coords__eclipe__text {
    width: 60px !important;
  }
  .modal__detail__title.coords_mobile {
    font-size: 0.6rem !important;
    color: #595959;

    margin-top: 10px;
  }
  .modal__detail__title.group_mobile {
    font-size: 0.5rem !important;
    color: #595959;

    margin-top: 10px;
  }
  .obs_details {
    width: 60px;
  }
  .eclipe__text {
    width: 60px !important;
  }
  .coords__eclipe__text {
    width: 60px !important;
  }
  .author__eclipse__text {
    max-width: 150px !important;
  }
}

.menubas__mobile {
  height: 45px;
  display: none;
  width: 100%;
}
.mobile__menu__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow-x: auto;
}
.react-date-picker__inputGroup__input {
  min-width: 0em !important;
}

.progressContainer{
  background: rgba(128, 128, 128, 0.37);
}

.barCompleted {
  background-color: lightblue;

}
.progressBar_container{
  position: fixed;
  bottom: 0px;

}
.btn__hover:hover {
   color: white !important;
   text-decoration: none !important;
   transition: .3s ease-in-out !important;
   background-color: #025a99 !important;
 }
