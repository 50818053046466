.export-modal-btn{
  width: 120px;
}
.export-modal-footer{
  padding: 0 0 0 450px;
  margin: 0;
}
.export-modal-header{
  font-size: 24px;
  font-weight: 800;
  color: #fafafa;
  margin-left: 32px;
}
.export-modal-title{
  padding: 50px 48px;
  align-items: center;
}
.project-name-text{
  margin-right: 15px;
  font-size: 20px;
  font-weight: 500;
  color: #042b4a;
}
.obs-total-text{
  font-size: 14px;
  color: #073b60;
}
.export-modal-body{
  margin: 25px 110px 200px 110px;
  width: 400px;
}
.export-modal-body-parts{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
  height: 52px;
  font-size: 14px;
;
  color: #595959;
}
.export-modal-body-parts.input{

  padding-right: 20px  ;

}
.pats-imgs-total-text{
  font-size: 12px;
}
.export-modal-size{
  width: 620px;
}
.export-modal-size p{
  margin-bottom: 0;
}
.include-toggles{
  width: 135px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.include-toggles span{
  color: #073b60;
  font-weight: 600;
  font-size: 12px;
}
.export-date-input{
  width: 135px;
  height: 32px;
  color: #073b60;
  font-size: 12px;
}

