.loader {
  background: conic-gradient(transparent, #00255a);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: load 2s linear infinite;
}
.loader::before {
  content: "";

  width: 73%;
  height: 73%;
  border-radius: 50%;
  background-color: none;
}
@keyframes load {
  100% {
    transform: rotate(360deg);
  }
}

.loader__container {
  height: 100vh;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  opacity: 2;
  z-index: 1050; /* Higher value than the default modal's z-index */
  background: rgba(0, 0, 0, 0.3);
}
.modal__loader__container{
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  opacity: 2;
  z-index: 1050; /* Higher value than the default modal's z-index */
  background: rgba(0, 0, 0, 0.3);

}
.map__loader_container {
  position: absolute;
}
