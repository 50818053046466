.flex-container {
  display: flex;
  background-color: white;
  height: 100vh;
}

.admin-content {
  padding-top: 46px;
  padding-bottom: 100px;
  overflow: scroll;
}

.title-text {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  color: #042b4a;
}

.admin-btn-dark {
  width: 166px;
}

.eye-close {
  position: absolute;
  right: 11px;
  top: 31px;
}

.eclose {
  top: 35px !important;
}

.rowtop {
  border-bottom: 0.5px solid #cccccc;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.winput {
  width: 80% !important;
}

@media (min-width: 873px) {
  .inpttop {
    width: 160px;
  }
}

.modal-footer {
  background-color: #f5f7f8 !important;
}

.infochangepass {
  color: #e8edf2;
  background-color: #5b5e61;
  position: relative;
  top: -2px;
}

.modal-body {
  padding: 0px;
}

.btn-validate1 {
  color: #073b60;
  background: #fff;
  /* width: 200px; */
  width: 166px;
  text-align: center;
  border: 1px solid #073b60 !important;
}

.btn-validate1:hover {
  background: #073b60;
  color: #fff;
}

.PhoneInputCountry {
  position: absolute !important;
  bottom: 14px !important;
  left: 15px !important;
}

.PhoneInputCountry {
  height: 40px;
  bottom: 2px !important;
  left: 0px !important;
  padding-left: 15px;
  padding-right: 10px;
}

.PhoneInputInput {
  padding-left: 60px;
}

.PhoneInput:focus {
  /* border: 1px solid #ced4da !important; */
  border-color: #86b7fe !important;
}

.mb-10 {
  margin-bottom: 70px;
}

p {
  --highlight-color: yellow;
}

.simple-phone-input-sri198-dropdown.active {
  z-index: 1;
}

.simple-phone-input-sri198-main {
  height: 40px;
}

.simple-phone-input-sri198-dropdown-container,
.simple-phone-input-sri198-main {
  background: white !important;
}

.simple-phone-input-sri198-dropdown-container {
  border-right: none;
}

.yo {
  border: red 3px solid;
}

.textSizeColr {
  color: #095388 !important;
  font-weight: 500 !important;
}
