.sirenLogoContainer {
  background-color: var(--communityBlue);
  padding: 5px;
   width: 115px !important;
  height: 40 !important;
  position: absolute;
  top: 30px;
  left: 40px;
}

.sirenLogoContainer img{
  width: 100%;
  max-height: 5vh;
}

.studyHeaderImgContainer img{
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-height: 75vh;
}

.studyHeader {
  position: relative;
}

.studyHeaderFloating {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  text-align: center;
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: 30%;
  left: 0;
  right: 0;
  margin: auto;

}

.studyHeaderTitle {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.322);
  color: white;

}

.studyHeaderTitle h1 {
  font-weight: 600;
}

.studyDownloadBtn {
  font-weight: 500;
  background-color: var(--orangeButton);
  padding: 0.2rem 1.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 8px;
  border: solid 3px var(--orangeButton);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  position: relative;
  text-decoration: none;
  color: var(--foregroundBlue);
  cursor: pointer;
}

.studyDownloadBtn::after {
  position: absolute;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  background-color: transparent;
  content: "";
  z-index: 2;
  border-radius: 8px;
}

.studyDownloadBtn:hover::after {
  border: solid 3px var(--foregroundBlue);
  transition: .2s ease-in-out;
}

.studyDownloadBtn:hover {
  font-weight: 600;
  color: var(--foregroundBlue);
}

.studyDownloadBtn:active:after {
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  border: solid 5px var(--orangeButton);
}

@media (max-width: 1920px) {
  .studyHeaderTitle h1{
    font-size: 60px;
  }

  .sirenLogoContainer {
    top: 30px;
    left: 30px;
    padding: 2px;
  }

  .sirenLogoContainer img {
    max-height: 5vh;
  }


}

@media(max-width: 1200px) {
  .studyHeaderTitle h1{
    font-size: 40px;
  }

  .sirenLogoContainer {
    top: 30px;
    left: 30px;
    padding: 2px;
  }

  .sirenLogoContainer img {
    max-height: 5vh;
  }
}

@media(max-width: 1024px) {
  .studyHeaderTitle h1{
    font-size: 30px;
  }

  .sirenLogoContainer {
    top: 25px;
    left: 25px;
    padding: 2px;
  }

  .sirenLogoContainer img {
    max-height: 4vh;
  }
}

@media(max-width: 768px) {
  .studyHeaderTitle h1{
    font-size: 16px;
  }

  .sirenLogoContainer {
    top: 15px;
    left: 15px;
    padding: 2px;
  }

  .sirenLogoContainer img {
    max-height: 3vh;
  }

  .studyHeaderFloating {
    gap: .5rem;
  }

  .studyDownloadBtn {
    padding: .5rem;
    border-radius: .4rem;
    font-size: 10px;
  }
}

@media(max-width: 450px) {
  .studyHeaderTitle h1{
    font-size: 12px;
  }

  .sirenLogoContainer {
    top: 12px;
    left: 12px;
    padding: 2px;
  }

  .sirenLogoContainer img {
    max-height: 3vh;
  }

  .studyHeaderFloating {
    gap: .4rem;
  }

  .studyDownloadBtn {
    padding: .4rem;
    border-radius: .4rem;
    font-size: 10px;
  }
}
