.addProject__container {
  height: 100%;
  overflow-y: auto;
  padding: 30px 0px;
}
.addProject__btn {
  position: fixed;
  bottom: 0px;
  background: white;
  padding: 15px 0px;
  overflow: hidden;
  /* opacity: 1; */
  /* z-index: 9999; */
  width: 80%;
}
.addProject__btn2 {
  position: fixed;
  bottom: 0px;
  background: white;
  padding: 15px 100px;
  margin-left: 5px;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  /* opacity: 1; */
  /* z-index: 9999; */
  width: 80%;
}

.flex-container {
  display: flex;
  background-color: white;
  height: 100vh;
}

.admin-content {
  padding-top: 46px;
  padding-bottom: 100px;
  overflow: scroll;
}

.title-text {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  color: #042b4a;
}

.title-text2 {
  font-weight: 600;
  color: #042b4a;
}

.admin-btn-dark {
  width: 166px;
}

.eye-close {
  position: absolute;
  right: 11px;
  top: 31px !important;
}

.eclose {
  top: 35px !important;
}

.rowtop {
  border-bottom: 0.5px solid #cccccc;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.winput {
  width: 80% !important;
}
.site__input {
  border: none;
  border-bottom: 0.5px solid #cccccc;
  border-radius: 0px;
  outline: none;
  color: #073b60;
  font-weight: bold;
  width: 90%;
}
.segment__input {
  border: 0.5px solid #cccccc;
  border-radius: 0px;
  color: #073b60;
  font-weight: bold;
  outline: none;
  border-radius: 10px;
  width: 90%;
}
.segment__input_edit {
  border: 0.5px solid #cccccc;
  border-radius: 0px;
  color: #073b60;

  text-shadow: 4px -6px 12px rgba(26, 88, 159, 0.247);
  font-weight: bold;
  outline: none;
  border-radius: 10px;
  width: 90%;
}
.site__input::placeholder,
.segment__input_edit::placeholder,
.segment__input::placeholder {
  font-weight: normal;
}
.segment__input:focus {
  border: 1.5px solid #073b60;
}
.site__input:focus {
  border-bottom: 1.5px solid #073b60;
}
.site__field_wrapper {
  display: flex;
  padding: 0px 5px;
  justify-content: space-between;
}
.segment__field_wrapper {
  display: flex;
  padding: 0px 5px;
  justify-content: space-between;
  align-items: center;
}

.segment__addBox {
  background: white;
  border-radius: 0px 0px 8px 8px;
  padding: 16px 14px 20px 14px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.segment_feild {
  /* border-bottom: ; */
  min-height: 40px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  color: #808080;
}
.segment_feild.read {
 
  cursor: default;

}
.addSegment__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 38px;
  border: 0.5px solid #cccccc;
  border-radius: 10px;
}
.site_lable {
  color: #808080;
}
.segment_close_icon {
  color: #b3b3b3;
  font-size: 1.4rem;
  cursor: pointer;
}
.segment_chevron {
  color: #b3b3b3;

  font-size: 2rem;
}

.segment_feild_icon {
  transform: rotate(0deg);
  transition: 0.3s ease-out;
}
.segment_feild_icon_rotate {
  transform: rotate(180deg);
  transition: 0.3s ease-in;
}
.site_feild_icon {
  /* color: #073b60; */
  color: #808080;
  cursor: pointer;
  font-size: 1rem;
}
.segment_feild_icon {
  /* color: #073b60; */
  color: #808080;
  cursor: pointer;
  font-size: 1.5rem;
}
.add_segments__container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  height: auto;
}
.added__segment {
  border: 1px solid #073b60;
  padding: 3px 5px;
  font-weight: bold;
  cursor: default;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3px 5px;
  color: #073b60;
  cursor: pointer;
  border-radius: 8px;
}
.selectedSegment {
  border: 1px solid #073b60;
  padding: 3px 5px;
  font-weight: bold;
  cursor: default;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: #e5f4ff;
  margin: 3px 5px;
  color: #073b60;
  border-radius: 8px;
}
@media (min-width: 873px) {
  .inpttop {
    width: 160px;
  }
}


.bgr {
  background-color: red;
}

.posradio {
  position: absolute;
  right: 10px;
  bottom: 5px;
}

.pospub {
  position: absolute;
  left: 0px;
  bottom: 0px;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}

.form-select:focus {
  box-shadow: none;
}

.error {
  color: red;
}

.is-invalid {
  border: 1px solid red;
  outline: none !important;
  box-shadow: none !important;
}
.is-invalid:focus {
  border: 1px solid red !important;
  outline: none !important;
  box-shadow: none !important;
}

.h90 {
  height: 90px;
}

.poserr {
  position: relative;
  bottom: 15px;
}

.modal_obs__btn.this {
  width: 175px !important;
}

.divide {
  margin-top: 15px;
}

.procloseico {
  position: absolute;
  top: 10px;
  right: 30px;
}

.newpromt {
  margin-top: 3px;
}
