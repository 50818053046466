@media (min-width: 576px) {
  .modal-sm {
    max-width: 320px !important;
  }
}
.confrim__modal {
  font-family: "Poppins", sans-serif;
}
.close__icon {
  font-size: 20px;
  cursor: pointer;
  color: #b3b3b3;
  font-weight: bold;
}
.close__icon:hover {
  color: #cecece;
}
.validbtn {
  font-weight: 600;
  padding: 15px !important;
}

.validbtn:hover {
  background-color: #d6e1ea;
}
.confirmModal {
  background: rgba(0, 0, 0, 0.8) !important;
}
