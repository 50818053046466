@import url("https://fonts.googleapis.com/css2?family=Aclonica&family=Nunito:wght@300&family=Poppins:wght@200;300;400;500;600;700&display=swap");

.main-list-view .rgt-wrapper {
  width: 100% !important;
  height: calc(100% - 90px) !important;

  position: fixed !important;
  /* cursor: grab !important; */

  font-family: "Poppins", sans-serif !important;
  overflow-y: hidden !important;
  font-size: 0.8rem !important;
}

.rgt-container {
  -ms-overflow-style: none;

  scrollbar-color: transparent transparent;
}

.rgt-cell:not(.rgt-row-edit):not(.rgt-row-not-selectable) {
  cursor: pointer;
}

.rgt-cell-header-inner {
  display: flex;
  background: none !important;
  margin: 0px !important;
  padding: 0px !important;
}
.rgt-row-hover {
  background: #f7f7f7;
}

.rgt-columns-manager-wrapper {
  display: none !important;
}
.rgt-footer {
  background: #cac7c7 !important;
}
.rgt-row-even {
  background: #efefef !important;
}

.rgt-cell-header {
  color: #111315;
  width: 100% !important;
  padding: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  background: #dbecf7 !important;
  opacity: 1;
}

.obsId {
  text-decoration: underline;
  color: #135d95;
  /* width: 40px; */

  cursor: pointer !important;
  font-weight: bold;
}
.rgt-resize-handle:hover {
  background: grey !important;
  width: 2px;
}
.obsTableCheckBox input[type="checkbox"] {
  zoom: 1.2; /* Increase the size proportionally */
  -webkit-transform: scale(1.2); /* for Safari/Chrome */
  -moz-transform: scale(1.2); /* for Firefox */
  -ms-transform: scale(1.2); /* for IE */
  -o-transform: scale(1.2); /* for Opera */
  transform: scale(1.2);
  cursor: pointer !important;
}
.image-item {
  display: flex;
  margin: 10px 15px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid blue solid red;
}
.image-item__btn-wrapper {
  display: flex;

  margin-left: 10px;
}
.tableImage {
  color: transparent;
  height: 270px;
  width: 260px;
}

.tableImage:hover {
  filter: grayscale(60%);

  transition: 0.2s ease;
  color: white;
}
.imageWrapper {
  margin: 10px;
  color: transparent;
  height: 270px;
  width: 260px;
}
.imageWrapper:hover {
  color: white;
}
.modalBody {
  font-size: 14px !important;
}
.img-modal-container {
  display: flex;
  background: transparent !important;
  justify-content: center;
  align-items: center;
}
.modalImgBody .modal-content {
  background: transparent !important;
}
.img-modal-container img {
  max-height: 100%;
  max-width: 100%;
}
.crud_select,
.crud-input,
.crud_select_class {
  border: 0.5px solid #b3b3b3;
  height: 30px;
  font-size: 0.8rem;
  border-radius: 5px;
}

.modals {
  border-top-left-radius: 20px !important;
}
.crud-input:focus {
  border: 1px solid blue solid #135d95 !important;
  outline: #135d95;
}

option {
  color: gray;
}
.rBottom {
  rotate: 180deg;
}

.sortB {
  position: absolute;
  right: 30px !important;
  top: 13px;
}

.rgt-cell-inner {
  margin: 0px !important;
}

.is-invalid {
  border: 1px solid blue solid red;
}
.error {
  color: red;
  font-size: 0.7rem;
}
.table__number__align {
  text-align: right !important;
  padding-right: 30px !important;

}
