.LandingFooter {
  padding: 60px 7.5rem;
  background-color: var(--cardBackground);
}

.LandingFooter > p {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
}

.landingFooterAmmcoEmail {
  cursor: pointer !important;
}

.landingFooter-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 60px;
  gap: 1rem;
}

.landingFooterAmmco > h4 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}

.landingFooterAmmcoInfo {
  font-size: 18px;
}

.landingFooterAmmcoWrapper {
  display: flex;
  gap: 2rem;
}

.landingFooterContactIcons > img {
  max-width: 2rem;
}

.titleBackgroundFooter {
  color: var(--primaryBlue);
  font-size: 3rem;
  height: 6.25rem;
  margin-bottom: 4px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

@media (max-width: 1200px) {
  .LandingFooter {
    padding: 60px 5rem;
  }
}

@media (max-width: 1024px) {
  .LandingFooter {
    padding: 40px 2rem;
  }

  .landingFooter-footer {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    gap: 2rem;
    padding-top: 40px;
  }

  .landingFooterAmmcoWrapper {
    flex-direction: column;
    gap: 1rem;
  }
}

@media (max-width: 768px) {
  .LandingFooter {
    padding: 40px 1.25rem;
  }

  .landingFooterAmmcoWrapper {
    flex-direction: column;
    gap: 1rem;
  }
}
