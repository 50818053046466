
.dashboard__wrapper{
    background:#E9EBEE ;
    padding-bottom: 20px;
    min-height: 100vh !important;
    
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    color: #073B60;
    width: 100%;

 
    --dashboard-color: #073B60;
}
.dashboard__container{
    width: 100%;
    overflow-x: hidden;
    margin:0px auto;
    padding: 10px ;
   
}
.dashboard__top__header{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

}

.dashboard__datepicker { 
    display: flex;
    background: white;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding:10px 23px;
    border-radius: 8px;
}
.dashboard__datepicker .ant-form{
    height: 35px !important;
}
.dashboard__datepicker .date__picker__form{
   
    width: 100%;
    justify-content: space-between;
}
.stats_obs__container{
    display: flex;
    width: 100% !important;
    min-width: 350px; 
    flex-direction: column;
    height: 99%;
}
.barcharts__containers{
    display: flex;
    flex-direction: column;
    height: 99% !important;
}
.dashboard__top__obs{
    display: flex;
    background: white;
    width: 100%;
    justify-content: space-between;
    height: 60px;
    align-items: center;
    padding:10px 23px;
    border-radius: 8px;

}
.dashboard__top__obs button{
    background:var(--dashboard-color);
    color: white;
    padding: 3px 15px;
    border-radius: 5px !important;
}
.dashboard__top__obs .unvalid__total{
    font-size: 2rem;
    font-weight: 500;
}
.dashboard__top__obs .unvalid__text{
    font-size: 1.4rem;
    font-weight: 500;
}
.dashboard__body {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 1fr;
    grid-gap: 10px;
}
.dashboard__body .stats_obs{
    background: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    padding: 10px;

}
.dashboard__body .stats_obs h1{
    font-size: 1.8rem;
    margin-top: 1rem;
    color: var(--dashboard-color);
}
.dashboard__body .stats_obs span{
    font-size: .9rem;
    margin-top: 1rem;
    color: var(--dashboard-color);
}
.dashboard__body .stats_obs .list__dashboard__icon{
    width: 20px;
    height: 20px;
    color: var(--dashboard-color);
}
.dashboad__doughnut {
    margin-top: 20px;
    background: white;
    width: 100%;
 
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 5px;
    padding: 40px 20px;
}
.dashboad__doughnut h1{
    text-align: center;
    font-size: 20px;
    color: var(--dashboard-color);
}
.dashboad__doughnut .doughnut__container{
    margin-top: 60px;
}
.horizontal__barchart{
    background: white;
    width: 100%;
    
    border-radius: 5px;
    padding: 10px;

}

.vertical__barchart{
    background: white;
    width: 100%;
    margin: 10px 0px;
    display: flex;
    flex: 1;
    flex-direction: column;
    border-radius: 5px;
    padding: 10px;
}
.vertical__barchart div, .horizontal__barchart div{ 
    font-size: 1.2rem;
    padding-left: 40px;
    padding-top: 10;
    padding-bottom: 40px;
}

.dashboard__member__title h2{ 
    font-size: 1rem;
}
.dash__col3{
    display: flex;
    flex-direction: column;
    height: 99%;

   
}
.dash__col3 .stats__members{
    display: flex;
    flex-direction: column;
   margin-top: 20px;
    flex: 1;
}
.stats__members{
    background: white;
    width: 100%;
    margin-top: 10px ;
    text-align: center;
    border-radius: 5px;
    padding: 10px 20px;
}

.stats__members .top{
    display: flex;
    justify-content: space-between;
}
.stats__members .top span{
    font-size: 1.1rem;
}
.dashboard__member__title { 
    display: flex;
    justify-content: space-between;
    border-bottom: 1.5px solid var(--dashboard-color);
    padding-top: 20px;

}
.single__member{
    display: flex;
    justify-content: space-between;
    margin: 15px 0px;
}
.single__member .name{
    width: 140px;
    white-space: nowrap; /* Prevent text from wrapping */
    text-align: left;
    overflow: hidden; /* Hide any overflowing content */
    text-overflow: ellipsis; /* Display an ellipsis (...) to represent clipped text */


}

.doghnutContainer{
    display: flex ;
    justify-content: center ;
}
.linkDeco{
    color: white;
    text-decoration: none ;
  }
  .linkDeco:hover {
   color: white;
    text-decoration: none;
    transition: .3s ease-in-out;
  }
  .unvalid__view__btn:hover {
    background-color: #025a99;
    text-decoration: none;
    transition: .3s ease-in-out;
  }
  .verticalBarLoadingContaier{
    display: flex ;
    height: 100%;
    max-height: 230px ;
    justify-content: center ;
}
.horizontalBarLoadingContainer{
    display: flex ;
    justify-content: center ; 
    height: 100%;
    max-height: 380px;
}
  .horizontalBarChartCanvas{
    width: 100% !important;
    height: 100% !important; /* Change fixed height to percentage */
    max-height: 380px; /* Set a maximum height if needed */
    image-rendering: optimizeSpeed;             /* Older versions of browsers */
    image-rendering: -moz-crisp-edges;          /* Firefox */
    image-rendering: -o-crisp-edges;            /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (Safari) */
    image-rendering: optimize-contrast;         /* Modern browsers */
    -ms-interpolation-mode: nearest-neighbor; 
  }
  .verticalBarChartCanvas{
    width: 100% !important;
    height: 100% !important; 
    max-height: 230px !important;

    image-rendering: optimizeSpeed;             /* Older versions of browsers */
    image-rendering: -moz-crisp-edges;          /* Firefox */
    image-rendering: -o-crisp-edges;            /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (Safari) */
    image-rendering: optimize-contrast;         /* Modern browsers */
    -ms-interpolation-mode: nearest-neighbor; 
  }


/* Small devices (tablets) */
@media only screen and  (max-width: 767px) {
    .dashboard__body {
        grid-template-columns: 1fr ;
 
      
    }
    .dashboard__top__header {
        grid-template-columns: 1fr; 
      }
      .dashboard__top__obs .unvalid__total{
        font-size: 1.2rem;
        
    }
    .dashboard__top__obs .unvalid__text{
        font-size: .8rem;

    }
    
}

/* Medium devices (desktops) */
@media only screen and (min-width: 768px) and (max-width: 1030px) {
    .dashboard__body {
   
        grid-template-columns: 1fr 1fr;
    
      
    }
    .dashboard__top__obs .unvalid__total{
        font-size: 1.2rem;
        
    }
    .dashboard__top__obs .unvalid__text{
        font-size: 1rem;

    }
    
    /* CSS styles for medium devices */
}

/* Large devices (desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    /* CSS styles for large devices */
}

@media only screen and (min-width: 1200px) {
    .dashboard__container{
        width: 90%;

    }
}
/* Extra large devices (large desktops) */
@media only screen and (min-width: 1500px) {
    .dashboard__container{
        width: 85%;

    }
}