.menu-content {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
    position: absolute !important;
    background-color: #ffffff !important;
    width: 232px !important;
    /* top: 2px !important;
    left: 0px !important; */
  }
  .menu-content {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    padding-top: 5px !important;
  }
  .menu-content {
    color: #073b60 !important;
  }
  .menu-content a {
    text-decoration: none !important;
    padding: 12px 24px !important;
    border-bottom: solid rgba(0, 0, 0, 0.15) !important;
    color: #073b60 !important;
    cursor: pointer !important;
  }
  .menu-content a:hover {
    background-color: rgba(177, 219, 252, 0.207) !important;
  }
  .dashboardActive{
    background-color: #073b60 !important;
  }
  .projectSelected {
    background-color: #e0f1fe;
  }
  
  .hambuger__menu {
    padding-bottom: 20px !important;
    margin-bottom: 20px !important;
    display: inline-block !important;
  }
  .mobileMenu__container {
    position: fixed !important;
    top: 0px;
    width: 100% !important;
    height: 100vh !important;
    transition: 0.3s ease-in;
    opacity: 9999 !important;
    z-index: 1000;
    background: white !important;
  }
  .mobile__menuTop {
    display: flex;
    width: 100% !important;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  .menu__mobile__text {
    font-size: 20px;
    font-weight: 600;
  }
  
  .mobile__menu__items {
    display: flex;
    justify-content: center;
    color: #073b60;
    font-weight: 500;
    align-items: center;
  }
  .mobile__menu__items.sub {
    margin-left: 20px;
    display: flex;
    padding: 10px 0px;
    justify-content: center;
    color: #073b60;
    cursor: pointer;
    font-weight: 500;
    align-items: center;
  }
  .mobile__menu__items.sub a {
    color: #073b60;
  }
  
  .mobile__icon {
    width: 20px;
  }
  
  @media (min-width: 900px) {
    .mobileMenu__container {
      display: none;
    }
  }
  .projectLitLoading{
    cursor: not-allowed !important;
  }
  
.tailledeletemodal {
    /* padding-left: 30px !important;
    padding-right: 30px !important; */
    width: 400px;
  }
  
  @media (min-width: 576px) {
    .modal-sm {
      max-width: 360px;
    }
  }
  
  .validbtn {
    font-weight: 600;
    padding: 15px !important;
  }
  
  .cancelbtn {
    font-weight: 400;
    padding: 15px !important;
  }
  
  .validbtn:hover,
  .cancelbtn:hover {
    background-color: #d6e1ea;
  }
  
  .projectlist a {
    border: none !important;
  }
  
  .projectlist {
    max-height: 300px;
    overflow: auto;
  }
  
  .projectSelected {
    background-color: #e0f1fe;
  }
  
  .noProject {
    color: rgba(255, 255, 255, 0.4);
    fill: red;
  }
  
  .noPro {
    fill: rgba(255, 255, 255, 0.4);
  }
  
  .wIco {
    fill: white !important;
  }
  
  .fillblue {
    fill: #135d95;
  }
  
  .projectBtn {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 160px;
    white-space: nowrap;
    display: inline-block;
    position: relative;
    top: 6px;
  }
  
  .succedmsg {
    position: fixed;
    left: 230px !important;
    background-color: #e1f7dd;
    width: 100%;
    z-index: 1;
    top: 50px !important;
    font-size: 18px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #138101;
  
  }
  
  .succedmsg2 {
    position: absolute;
    width: 100%;
    top: 0px;
    font-size: 18px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #138101;
    background-color: #e1f7dd;
    z-index: 1;
  }
  
  .form-control:focus {
    border: 1.5px solid #095388 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    color: #095388;
    font-weight: 500;
    outline: none !important;
  }
  
  .updateInp {
    border: 2px solid #095388 !important;
    color: #095388;
    box-shadow: none !important;
    font-weight: 500;
  }
  
  .changedInput {
    border: 1.5px solid #095388 !important;
    color: #095388 !important;
    font-weight: 500 !important;
    border-radius: 0.25rem;
  }
  
  .changedInput > div > div > div.css-1dimb5e-singleValue {
    color: #095388 !important;
  }
  
  .css-1nmdiq5-menu {
    color: #424141 !important;
    font-weight: 400 !important;
  }
  
  .rgt-cell-header-id {
    background-color: #dbecf7 !important;
  }
  .rgt-cell-header {
    color: #111315;
    width: 100% !important;
    padding: 5px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    background: #dbecf7 !important;
    opacity: 1;
  }
  
  @media screen and (max-width: 580px) {
    .title-text {
      font-size: 15px !important;
     
    }
    .admin-content {
      padding-top: 25px !important;
  
    }
  .form-control{
    font-size: .9rem !important;
  }
  .css-1dimb5e-singleValue{
    font-size: .9rem !important;
  }
  .offset-md-1 label{
    font-size: .9rem !important;
  }
  .export-btn{
  
    font-size: .8rem !important;
    width: 120px !important;
    height: 40px !important;
  }
  .admin-btn-dark {
    width: 120px !important;
    font-size: .8rem !important;
  }
  .addProject__btn {
  
    padding: 10px 0px;
  
  width: 58% !important;
  
  }
  .segment_feild {
  font-size: .7rem !important;
  }
    
  }