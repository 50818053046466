.login-page {
  overflow-x: hidden;
  height: 100vh !important;
  overflow-y: hidden;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 0 !important;
  position: fixed;
}
/* .mystyleAjust{
    border: 1px solid red;
    padding: 29px;
    
} */
.rememberMe {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.form-control:hover {
  border: 1px solid #3a8ac9;
  cursor: pointer;
}
.form-control:focus {
  border: 1.5px solid #1565c0 !important;
  box-shadow: none !important;

  text-shadow: 2px -3px 6px rgba(26, 88, 159, 0.247);
  outline: none !important;
}
.is-invalid {
  border: 1px solid red;
  outline: none !important;
  box-shadow: none !important;
}
.is-invalid:focus {
  border: 1px solid red !important;
  outline: none !important;
  box-shadow: none !important;
}
.loadingContainer {
  display: flex;
  height: 40px;

  justify-content: center;
  align-items: center;
}
.login-btn {
  background: #073b60;
  height: 40px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border-radius: 5px;
  border: none !important;
  outline: none;
  color: white;
}
.login-btn:disabled {
  background: #073b60;
  opacity: 0.3;
  border-radius: 5px;
  cursor: not-allowed;
}
.login-btn:hover {
  background: #075796;
  border: none !important;
  color: white;
  transition: 0.3s ease-out;
}
.login-btn:active {
  background: #042b4a;
  border: none !important;
  color: white;
  transition: 0.3s ease-out;
}
.register__container {
  display: flex;
}
.register__form {
  width: 80%;
}
.form__section {
  width: 50%;
}
.register__fixed__div {
  height: 70vh;
  overflow-y: auto;
}
.register__spacer {
  height: 120px;
}
.form-control {
  background-image: none !important;
}
/* .control_phone {
  border: 1px solid red !important;
} */
@media screen and (max-width: 595px) {
  .form__section {
    width: 100%;
  }
  .register__form {
    width: 90%;
  }
  .register__spacer {
    height: 200px;
  }
  .error__notif.login__error{
    margin: 0px 10px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: .9rem;
    margin-top: 5px;

  }
  .error__notif.register__error{
    margin-right: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: .9rem;
    margin-top: 5px;
   
  }
 
}
@media only screen and (max-width: 485px) {
  .login-page {
    overflow-x: hidden;
    height: 100vh !important;
    overflow-y: hidden;

    position: fixed;
  }
}
@media only screen and (max-width: 380px) {
  .login-page {
    overflow-x: hidden;
    height: 100vh !important;
    overflow-y: hidden !important;

    position: fixed;
  }
}
.simple-phone-input-sri198-dropdown-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  background: white !important;
  border-right: none !important;
}
/* 586 */
