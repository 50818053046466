*,
*::before {
  box-sizing: border-box;
}

.toggle-content {
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.26s ease;
}

.toggle-content[aria-expanded="true"] {
  transform: scaleY(1);
}

.checker {
  position: absolute;
  top: 14px;
  right: 10px;
}

/* .check-toggle{
content: url("../images/icon/collapsecheck.svg");
}

.check-empty{
content: url("../images/icon/emptycheck.svg");
}*/

.check-checked {
  width: 19px;
  height: 19px;
}

.n1 {
  background-color: #ffffff !important;
}

.tognbr {
  font-size: 13px;
  margin-left: 5px;
  position: relative;
  top: 1px;
}

.clbl {
  color: #075796 !important;
}

.true {
  font-weight: bold;
  color: #124f7e !important;
}

.nivo {
  padding: 10px !important;
}

.tleft {
  text-align: left;
}

.niv {
  display: flex;
  padding-top: 13px;
  padding-bottom: 13px;
  line-height: 1;
  justify-content: left;
  /* text-align: left; */
}

.btn-toggle-nav li,
.nav-item {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.fw-bold {
  font-weight: 700 !important;
}

.clbl {
  color: #075796 !important;
}

.tognbr {
  font-size: 12px;
  line-height: 18px;
}

.nivo2 {
  background: rgba(177, 219, 252, 0.1) !important;
}

.nivo3 {
  background: rgba(177, 219, 252, 0.2) !important;
}

.nivo4 {
  background: rgba(177, 219, 252, 0.3) !important;
}

.nivo5 {
  background: rgba(177, 219, 252, 0.4) !important;
}

.nivo2:hover,
.nivo2:focus {
  box-shadow: inset 2px 2px 50px rgba(83, 175, 248, 0.2) !important;
}
