#howItWorks {
  padding-top: 60px;
}

.worksCardMeasures {
  border: none;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--cardBackground);
  padding-bottom: 2rem;
  gap: 2rem;
}

.howItWorksSection {
  background-color: var(--cardBackground);
}

.cardImage {
  width: 8rem;
}

.cardBody {
  max-width: 20rem;
  padding: 1rem 0;
}

.cardNumber {
  font-size: 4rem;
  font-weight: 600;
  width: 2.5rem;
  min-width: 2rem;
  color: var(--communityBlue);
  margin: 0;
  text-align: center;
}

.cardTitle {
  color: var(--communityBlue);
  font-size: 2.5rem;
  line-height: 2.25rem;
  margin-left: 1.59rem;
  margin-bottom: 0.6rem;
}

.cardText {
  font-size: 1rem;
  color: var(--communityBlue);
  font-weight: 400;
}

.howItWorksbtnWrapper {
  display: flex;
  justify-content: center;
  padding: 0 2rem 4rem;
}

.howItWorksRow {
  padding: 40px 7.5rem 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 0rem 2rem;
}

@media (max-width: 1200px) {
  .cardTitle {
    font-size: 2.25rem;
  }

  .howItWorksRow {
    padding: 40px 5rem 1rem;
  }
}

@media (max-width: 1024px) {
  #howItWorks {
    padding-top: 40px;
  }

  .howItWorksRow {
    grid-template-columns: 1fr;
    padding: 40px 12rem 3rem;
    gap: 2rem;
  }

  .cardTitle {
    font-size: 2.5rem;
  }

  .howItWorksbtnWrapper {
    display: flex;
    padding: 0 2rem 4rem;
  }

  .worksCardMeasures {
    margin-bottom: 0rem;
    padding-bottom: 0rem;
  }
}

@media (max-width: 950px) {
  .howItWorksRow {
    padding: 40px 10rem 3rem;
  }
}

@media (max-width: 850px) {
  .howItWorksRow {
    padding: 40px 8rem 3rem;
  }
}

@media (max-width: 768px) {
  .cardBody {
    margin-inline: 0.8rem;
    padding: 0;
  }

  .cardTitle {
    font-size: 1.75rem;
  }
  .cardText {
    font-size: 0.8rem;
  }

  .cardImage {
    width: 6rem;
  }

  .howItWorksRow {
    grid-template-columns: 1fr;
    padding: 40px 6rem 3rem;
    gap: 2rem;
  }
}

@media (max-width: 600px) {
  .cardImage {
    display: none;
  }

  .howItWorksRow {
    grid-template-columns: 1fr;
    padding: 40px 1.5rem 3rem;
    gap: 2rem;
  }

  .cardTitle {
    font-size: 2rem;
    margin: 0;
  }

  .cardText {
    font-size: 1rem;
  }

  .cardBody {
    margin: 0;
  }

  .howItWorksbtnWrapper {
    display: flex;
    padding: 0 2rem 4rem;
  }

  .worksCardMeasures {
    margin-bottom: 0rem;
    padding-bottom: 0rem;
    gap: 1.5rem;
  }
}

@media (max-width: 450px) {
  .howItWorksbtnWrapper {
    padding: 0 1rem 4rem;
  }

  .howItWorksbtnWrapper .smallBtn {
    width: 100%;
  }
}
