.rgt-cell-header-inner {
  font-size: 14px;
  color: #135d95;
  font-weight: bold;
  text-align: left;
}
.rgt-cell-header {
  color: #111315;
  width: 100% !important;
  padding: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  background: #dbecf7 !important;
  opacity: 1;
}

.rgt-sort-icon {
  display: inline-flex !important;
}

.rgt-wrapper {
  height: calc(100vh - 146px) !important;
}

.rgt-cell-id {
  /* border-right: 1px solid red !important; */
  padding-left: 5px;
}

.rgt-cell-inner {
  padding-left: 4px;
  margin-left: 2px;
}

.contentRole {
  position: relative;
  display: block;
  width: 100%;
  cursor: pointer;
}

.choiserole {
  position: absolute;
  right: 25px;
  top: 6px;
}

.roleselect {
  position: absolute;
  top: 26px;
  left: -10px;
  width: 140px;
  padding-top: 10px;
  border-radius: 2px;
  padding-bottom: 10px;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 5px 1px;
  background-color: white;
  z-index: 2;
}

.spanslt {
  padding: 10px 10px;
  display: block;
  cursor: pointer;
}

.spanslt:hover {
  background-color: #f0f5f7;
}

.selected {
  background-color: #dbecf7 !important;
}

.headerTitle {
  position: relative;
  width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.imgArrow {
  position: absolute;
  right: 0px;
  top: 6px;
}

.rTop {
  rotate: 0deg;
}

.rBottom {
  rotate: 180deg;
}

.sortB {
  position: absolute;
  right: 0px;
  top: 13px;
}

.txtadd {
  position: relative !important;
  left: 3px !important;
  bottom: 3px !important;
}

.modal_obs__btn {
  padding-left: 25px !important;
  padding-right: 25px !important;
  border: 2px solid #042b4a;

  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.fseltab {
  /* background-color: red !important; */
  text-align: right !important;
}
