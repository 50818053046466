.marker__img__container {
  height: 100%;
  width: 100%;
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 5px;
}

.marker-popup {
  background-color: white;

  display: flex;
  /* justify-content: space-between; */
  padding: 0px 14px;

  height: 160px;
  width: 390px;

  border-radius: 8px;
}
.popup__table {
  height: 150px !important;
  width: 100% !important;
  padding: 0 16px !important;
  margin: 0px 16px !important;
}

.obs_title {
  width: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #595959;
  /* line-height: 24px; */
}
.obs_details {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  margin-left: 20px;
  font-size: 12px;
  color: #073b60;
  line-height: 24px;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.obs__time {
  display: flex;
  width: 150px;
  justify-content: space-between;
}
/* {

} */

.marker-head {
  font-size: 12px;
  color: #073b60;
  line-height: 24px;
  cursor: pointer;
  width: 70px;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: bold !important;
}
.obs__btn__container {
  margin-left: 15px;
}
.obs-btn {
  color: white;
  font-size: 12px;
  border-radius: 24px;
  width: fit-content !important;

  display: flex;
  height: 20px;
  padding: 6px 8px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}
