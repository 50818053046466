.species-select-container {
  display: flex;
  justify-content: space-between;
}
.species-select-item {
  flex: 1 !important;
}
.modal-top {
  display: flex;
  background: #f0f0f0;
}
.modal-top.qa {
  display: block;
  background: #f0f0f0;
  min-height: 120px;

}
.modal-photo-btn {
  position: absolute;
  right: 0px;
  font-size: 0.9rem;
  top: 133px;
  /* z-index: 9999; */
  background: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  /* border-radius: 20px 0px 0px 0px; */
  padding: 8px 12px;
  color: white;
}
.modal-photo-btn.disabled {
  cursor: not-allowed;
  background: grey;
  opacity: 0.5;

  cursor: not-allowed;
}
.modal-photo-btn.disabled:hover {
  background: grey;
  color: white;
}
.modal-photo-btn:hover {
  background-color: white;
  transition: 0.5s ease-out;
  color: #096dbb;
}
.modal-photo-btn.create {
  position: absolute;
  right: 0px;
  font-size: 0.9rem;
  top: 192px !important;

  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  border-radius: 20px 0px 0px 0px;
  padding: 5px 10px;
  color: white;
}
.obs__type {
  background: #096dbb;
  color: white;
  font-weight: bold;
  border-radius: 50px;
  padding: 3px 10px !important;
}

.modal__obs__details__row {
  border-bottom: 1px solid #cccccc;
  font-size: 14px;
  color: #595959 !important;

  height: 53px;
  /* padding: 5px 0px; */
}
.modal__obs__details__row.projectName {
  border-bottom: 0.4px solid #cccccc !important;

  font-size: 14px;

  height: 45px;
  padding: 4px 0px;
}
.modal__detail__title {
  font-size: 0.9rem;
  color: #595959;
  /* margin-top: 10px; */
}
.modal-footers {
  display: flex;
  justify-content: center;
  padding: 10px 15px;
  background: #f0f0f0;
  align-items: center;
}
.modal-footers-2 {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0px;
  background: #f0f0f0;
  align-items: center;
}
.modal__title__value {
  color: #073b60 !important;
  height: 30px;
  margin-top: 10px;
}
.modal-footers .modal_obs__btn {
  border: 1.5px solid #073b60;
  border-radius: 7px !important;
  margin: 0px 10px !important;
  font-weight: 500;
  color: #073b60 !important;
  cursor: pointer !important;
  padding: 8px 0px !important;
  width: 140px !important;
  text-align: center !important;
}

.modal-footers .modal_obs__btn:hover {
  background: #b1dbfc;
  transition: 0.5s ease-out;
  color: #073b60;
}
.btn-validate {
  background: #073b60;
  color: #fff;
  border-radius: 10px;
  /* font-size: 0.8rem; */
  margin: 0px 10px;
  width: 150px;
  text-align: center;
}
.btn-validate2 {
  background: #9abbd2;
  color: #fff;
  width: 200px;
  text-align: center;
}

.btn-validateDesable {
  background: #7fadce;
  color: #fff;
  width: 200px;
  text-align: center;
}
.btn-validateDesable:hover {
  color: #fff !important;
}
.modal-footers .modal_obs__btn.validate {
  transition: 0.5s ease-out;
  color: white !important;
  background: #073b60;
  border: 1px solid #073b60;
}
.modal-footers .modal_obs__btn.validate:hover {
  transition: 0.3s ease-out;
  color: white;
  background: #075796;
  border: 1px solid #073b60;
}
.modal-size {
  max-width: 590px !important;
  /* height: 800px !important; */
}
.crud_select_class {
  font-size: 0.8rem;
}

.eclipe__text {
  width: 100px !important;
  white-space: nowrap;
  display: inline-block;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date__eclipe__text {
  width: 120px !important;

  white-space: nowrap;
  display: inline-block;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.author__eclipse__text {
  max-width: 250px !important;

  white-space: nowrap;
  display: inline-block;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.qa__amount:hover {
  cursor: pointer;
  text-decoration: underline;
}

.modalBody {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 349px;
}

.qa__display {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-top: 1rem;
}

.qa__display > div {
  width: 50%;
  display: flex;
  align-items: center;
}

.modal__gallery__bottom {
  display: flex;
  gap: 1rem;
  justify-content: center;
  padding-top: 1rem;
}

.modal__gallery_preview {
  width: 20%;
  height: 120px;
  position: relative;
}

.modal__gallery_preview:hover {
  cursor: pointer;
}

.modal__gallery__preview_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.not__selected__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 1;
}

.modal__gallery__centering {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 1rem;
}

.modal__gallery__centering__single {
  margin: 0 2.5rem;
}

.input-container {
  position: relative;
  z-index: 2;
 
}
.input-container input{
  width: 100%;
  position: relative;
  background: transparent;
  z-index: 2;
 
}
.input-container input[type=number]::-webkit-inner-spin-button,
.input-container input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide arrows in Firefox */
.input-container input[type=number] {
    -moz-appearance: textfield; /* Prevents the default spinner */
}
.input-container .unit {
  position: absolute;
  right: 7px;
  z-index: 1;
  top: 55%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #999;

}

.input-container input:focus + .unit {
  color: black;
}





