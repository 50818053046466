.crud-input:focus {
  border: 1px solid #135d95 !important;
  outline: #135d95;
}
.species-select-item input {
  color: red !important;
}
.modal-footers .modal_obs__btn {
  border: 1.5px solid #073b60;
  border-radius: 7px;
  margin: 0px 10px;
  font-weight: 500;
  color: #073b60;
  cursor: pointer;
  padding: 8px 0px;
  width: 140px;
  text-align: center;
}
.modal-footers .modal_obs__btn.validate {
  transition: 0.5s ease-out;
  color: white !important;
  background: #073b60;
  border: 1px solid #073b60;
}
.modal-footers .modal_obs__btn:hover {
  background: #b1dbfc;
  transition: 0.5s ease-out;
  color: #073b60;
}
.btn-validate {
  background: #073b60;
  color: #fff;
  border-radius: 10px;
  /* font-size: 0.8rem; */
  margin: 0px 10px;
  width: 150px;
  text-align: center;
}
.btn-validate2 {
  background: #073b60;
  color: #fff;
  width: 200px;
  text-align: center;
}

.modal-footers .modal_obs__btn.validate:hover {
  transition: 0.5s ease-out;
  color: white;
  background: #075796;
  border: 1px solid #073b60;
}
.modal-size {
  max-width: 580px !important;
}
.crud_select_class {
  font-size: 0.8rem;
}
.delete__img__icon__container {
  position: absolute;
  width: 100%;
  margin-left: 22px;
  z-index: 9999;
  height: 98%;
  background: transparent;
}
.delete__img__icon__container:hover {
  background: rgba(0, 0, 0, 0.4);
  transition: 0.2s ease-in;
}
.create__delete__img__icon__container {
  position: absolute;
  width: 100%;

  z-index: 9999;
  height: 100%;
  background: transparent;
}
.create__delete__img__icon__container:hover {
  background: rgba(0, 0, 0, 0.5);
  transition: 0.2s ease-in;
}
