.obs__popup-container {
  width: 100%;
  height: 100vh;
  /* background-color: rgba(0, 0, 0, 0.075); */
  z-index: 1;
}
.obs__popup {
  width: 120px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  opacity: 1;
  position: absolute;
  z-index: 9999;
  /* box-shadow: rgba(0, 0, 0, 0.139); */
  cursor: pointer;
  padding: 10px;
}
.obs__popup div a {
  text-decoration: none;
  color: #073b60;
}
.obs__popup div {
  text-decoration: none;
  padding: 2px 3px 5px 5px;
  width: 80%;
  color: #073b60;
  margin: 5px 5px;
}

.obs__popup div:hover {
  background: #f0f0f0;

  transition: 0.5s ease-out;
  border-radius: 5px;
  /* border-bottom: 2px solid #073B60; */
}
