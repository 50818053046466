.mapSlider__close_btn {
  cursor: pointer;
  width: 20px;
  top: 82px;
  z-index: 9999 !important;
  opacity: 2;
  transition: 0.5s ease-in;
  right: 270px;
  position: fixed;
}
.mapSlider__close_btn_hide {
  cursor: pointer;
  width: 20px;
  top: 82px;
  right: -200px;
  transition: 0.6s ease-out;
  position: fixed;
}
.mapslider__container {
  overflow-y: auto !important;
  position: absolute;
  /* top: 93px; */
  width: 278px;
  right: 0;
  height: 85vh;

  transition: 0.5s ease-in;
  box-shadow: rgba(14, 30, 37, 0.499) 0px 2px 4px 0px,
    rgba(8, 20, 26, 0.492) 0px 2px 16px 0px;

  background: white;
  padding: 10px;
  padding-bottom: 40px;
}

.mapslider__container_hide {
  overflow-y: auto !important;
  position: absolute;
  transition: 0.5s ease-out;
  width: 278px;
  right: -500px;
  /* right: 0px; */
  height: 85vh;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  /* width: 350px; */
  background: white;
  padding: 10px;
  padding-bottom: 40px;
}
.map__filters {
  z-index: 1 !important;
}
.map__filters__container {
  box-shadow: 0px 0px 8px rgb(0 0 0 / 25%);
  list-style: none;
  width: 245px;
  height: 95vh;
  /* width: 242.266px; */
  left: 0px;
  position: absolute;
  margin-top: 2px;

  background: white;
  transition: 0.3s ease-in-out;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.map__filters__container__hide {
  box-shadow: 0px 0px 8px rgb(0 0 0 / 25%);
  list-style: none;

  height: 95vh;
  width: 245px;
  left: -300px;
  position: absolute;
  margin-top: 2px;
  background: white;
  transition: 0.3s ease-in-out;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
/*   */

.content-projets {
  background-color: #ffffff;
  position: absolute;
  top: 40px;
  transition: 0.3s ease-in-out;
  left: 250px;
  font-size: 0.7rem;
  width: 200px;
  max-height: 400px;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: auto;
}
.content-projets-hide {
  background-color: #ffffff;
  position: absolute;
  top: -500px;
  left: 250px;
  transition: 0.3s ease-in-out;
  font-size: 0.7rem;
  width: 200px;
  max-height: 400px;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: auto;
}
.projectListDropdown {
  display: flex !important;
 
  flex-direction: column !important;
}

/* Sort styles  */
.sort__by__container {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  opacity: 1;
  z-index: 999s;
  padding: 0px !important;
  top: 42px;
  width: 250px;
  background: white;
  border-radius: 0px 0px 8px 8px;
  position: absolute !important;
}
.sort__header {
  font-size: 17px;
  padding: 10px 15px;
}
.sort__items {
  color: #073b60;
  padding: 15px 15px;
  cursor: pointer;
  border-top: 0.5px solid grey;
}
.sort__items a {
  text-decoration: none;
  color: #073b60;
}
.sort__items:hover {
  color: #073b60;
  background: #b1dbfc5c;
  transition: 0.2s ease-in-out;
}
.sort__close__btn {
  position: absolute;
  top: 0;
  cursor: pointer;
  right: 2px;
  margin-bottom: 10px;
}
.content-sort {
  width: 250px !important;
  border-radius: 0px 0px 8px 8px !important;
}
.clear__filter__btn {
  border: 1.5px solid #073b60;
  border-radius: 7px;
  margin: 0px 10px;
  font-weight: 500;
  color: #073b60;
  cursor: pointer;
  padding: 8px 0px;
  width: 140px;
  text-align: center;
}

.closeFilterBtn {
  position: fixed;
  top: 0 !important;
  z-index: 10 !important;
  bottom: 0px;
  margin-top: 80px !important;
  transition: 0.4s ease;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
}
.selectedFilters {
  position: fixed;

  top: 0 !important;
  z-index: 10 !important;
  bottom: 0px;
  margin-top: 95px !important;
  transition: 0.4s ease;

  max-width: 600px;
  height: fit-content;
}
.selected__filter__obs__list {
  display: flex;
  flex-wrap: wrap;
}
.selected__filter__obs__list div {
  margin: 5px;
  font-size: 0.7rem;
  background: white;
  border: 1px solid grey;
  padding: 3px 7px;
  border-radius: 20px;
}
.cancelFilter {
  cursor: pointer;
  margin-left: 5px;
}
.cancelFilter:hover {
  color: gray;
  transition: 0.2s ease-in-out;
}
.clear__filter__container {
  bottom: 5vh;
  position: absolute;
}
.clear__filter__btn:hover {
  background: #073b60;
  transition: 0.5s ease-out;
  color: #fff;
}
.mobile__filter {
  margin-top: 10px;
  margin-left: 10px;
  display: none;
}
.search__list {
  min-height: 180px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}
.search__btn {
  border-radius: 7px !important;
  margin: 0px 10px !important;
  font-weight: 500;
  background: #073b60 !important;
  cursor: pointer !important;
  padding: 8px 0px !important;
  color: white;
  font-size: 1.2rem;
  width: 140px !important;
  text-align: center !important;
}
.search__select {
  position: relative !important;
}
@media only screen and (max-width: 1500px) {
  .clear__filter__container {
    bottom: 10vh;
  }
}
@media only screen and (max-width: 1304px) {
  .mapslider__container {
    height: 93vh;
  }
  .mapslider__container_hide {
    height: 93vh;
  }
}
@media only screen and (max-width: 1166px) {
  .staffiltre {
    width: 10px !important;
  }
  /* .map__filters__container {
    width: 210px !important;
    font-size: 0.8rem !important;
  } */

  /* .mobile__show__closeFilterBtn {
    left: 200px !important;
  }
  .mobile__hide__filterBtn {
    left: -210px !important;
  } */
  .filter_desktop {
    display: none !important;
  }
  .mobile__filter {
    display: block;
  }
}
@media only screen and (min-width: 1300px) {
  /* .map__filters__container {
    height: 93vh;
  } */
  /* .map__filters__container__hide {
    height: 93vh;
  } */

  .mapslider__container {
    height: 93vh;
  }
  .mapslider__container_hide {
    height: 93vh;
    overflow-y: auto !important;
    position: absolute;
    transition: 0.5s ease-out;
    width: 278px;
    right: -400px;

    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    /* width: 350px; */
    background: white;
    padding: 10px;
    padding-bottom: 40px;
  }
}
@media only screen and (min-width: 2100px) {
  /* .map_content .leaflet-container {
    height: 95vh;
  } */

  .mapslider__container_hide {
    height: 95vh;
    overflow-y: auto !important;
    position: absolute;
    transition: 0.5s ease-out;
    width: 278px;
    right: -400px;

    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    /* width: 350px; */
    background: white;
    padding: 10px;
    padding-bottom: 40px;
  }
  .mapslider__container {
    height: 95vh;
  }
  /* .clear__filter__container {
    bottom: 30px;
  } */
}
@media only screen and (min-width: 2700px) {
  .mapslider__container_hide {
    height: 96vh;
    overflow-y: auto !important;
    position: absolute;
    transition: 0.5s ease-out;
    width: 278px;
    right: -400px;

    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    /* width: 350px; */
    background: white;
    padding: 10px;
    padding-bottom: 40px;
  }
  .mapslider__container {
    height: 96vh;
  }
}
.refresh_indicator{
  background-color: rgba(0, 0, 0, 0.4); /* Adjust the alpha value for transparency */
  position: fixed;
  height: 50px;
  width: 100%;
  z-index: 999999;
  display: flex;
  transition: ease-in-out 0.5s;
  align-items: center;
  justify-content: center;
 
}

.refresh_indicator span{
  position: absolute;
  opacity: 1;
  /* color: #073b60; */
  color: #fff;
}