.studyLayout {
  margin: 3rem 10rem;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* text-align: justify; */
  /* word-spacing:-2px; */
}

.studyApproach {
  display: flex;
  gap: 4rem;
}

.studyHeader {
  font-weight: bold;
  color: #579DAC;
}

.studyBulletTag {
  color: #65A09F;
}

.studyBullet {
  margin-left: 2rem;
}

.studyQuoteBlock {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 3rem;
  border-bottom-left-radius: 3rem;
  padding: 8rem;
  background-color: #EAF3F9;
  height: 100%;
  text-align: center;
  margin-right: -10rem;
}

.studyQuoteBlockText {
  border-top-left-radius: 3rem;
  border-bottom-left-radius: 3rem;
  padding: 4rem;
  background-color: #EAF3F9;
  min-height: 28rem;
  margin-right: -10rem;
}

.studyQuoteBlockText ul {
  list-style-position: outside;
}

.studyQuoteBlock h5 {
  width: 28rem;
  font-size: 20px;
}

.studyQuoteBlock p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.studyQuotee {
  width: 10rem;
}

.approachRight {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  width: 40rem;
}

.studyImpact {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.impactG1,
.impactG2 {
  display: flex;
  gap: 4rem;
}

.impactG1Right img,
.impactG2Left img {
  width: 100%;
}

.impactG2Left img {
  max-width: 120rem;
}

.impactQuote {
  color: white;
  background-color: #2578BC;
  margin: 0 -10rem;
  padding: 2rem 4rem 1rem;
  text-align: center;
  height: fit-content;
}

.impactQuote h5 {
  font-size: 28px;
}

.impactQuote p {
  font-style: italic;
  text-align: end;
}

.studySolutionImpactSiren {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
}

.approachLeft {
  width: 65%;
}

.approachRight {
  width: 35%;
}

.studyLeft {
  width: 50%;
}

.studyRight {
  width: 40%;
}

.studyLeft ul {
  list-style-position: outside;
}

.studySolutionImpactSiren {
  display: flex;
  justify-content: space-between;
  gap: 4rem;
}

.studyLeft,
.studyRight {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.studyTurtleContainer img {
  width: 100%;
}


.studyEndBorder {
  height: 1.5rem;
  margin: 0 -3rem;
  border: solid 1px #2578BC;
  background-color: #2578BC;
}

@media (min-width: 1920px) {
  .studyLayout {
    font-size: 18px;
  }
}

@media (min-width: 1201px) and (max-width: 1800px) {
  .studyQuoteBlock {
    height: 75%;
  }

  .approachRight {
   justify-content: center;
  }
}

@media (max-width: 1200px) {
  .studyApproach,
  .impactG1,
  .impactG2 {
    gap: 2rem;
    flex-wrap: wrap;
  }

  .approachLeft {
    width: 100%;
  }

  .approachRight {
    width: 100%;
  }

  .studyQuoteBlock, .studyQuoteBlockText {
    border-radius: 3rem;
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .studyLayout {
    margin: 2rem;
    font-size: 16px;
  }


  .studyApproach {
    margin-right: 0;
  }

  .studyApproach,
  .impactG1,
  .impactG2 {
    justify-content: center;
  }

  /* .studySolutionImpactSiren {
    text-align: center;
  } */
  .studySolutionBullets {
    text-align: left;
  }

  .studyLeft {
    width: 100%;
  }

  .studyRight {
    width: 100%;
  }

  .studyQuoteBlock {
    padding: 1rem;
  }

  .studyQuoteBlock h5 {
    width: 20rem;
    font-size: 16px;
  }

  .studyQuoteBlock p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
  }

  .studyBulletTag {
    font-size: 20px;
  }

  .studyQuotee {
    width: 10rem;
  }
}
