.patrol_mainTable .rgt-wrapper {
  width: 100% !important;
  height: calc(100% - 100px) !important;
  z-index: 0.9 !important;
  position: fixed !important;
  /* cursor: grab !important; */

  font-family: "Poppins", sans-serif !important;
  overflow-y: hidden !important;
  font-size: 0.8rem !important;
}
.rBottom {
  rotate: 180deg;
}

.sortB {
  position: absolute;
  right: 20px !important;
  top: 13px;
}

.patrole__subHeader {
  position: relative;
  z-index: 2 !important;
  height: 50px;
  left: -0.25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.patrol_subHeader_text {
  margin-left: 55px;
  font-weight: bold;
}
