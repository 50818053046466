.time-picker-input-container {
  width: 100% !important;
}
.time-picker-input {
  border: 0.5px solid #b3b3b3 !important;
  margin-left: 7px !important;
  height: 25px !important;
  width: 100% !important;
}

.crud-input:focus {
  border: 1px solid #135d95 !important;
  outline: #135d95;
}
.species-select-item select {
  width: 100% !important;
}
.modal-footers .modal_obs__btn {
  border: 1.5px solid #073b60;
  border-radius: 7px !important;
  margin: 0px 10px !important;
  font-weight: 500 !important;
  color: #073b60 !important;
  cursor: pointer !important;
  padding: 8px 0px !important;
  width: 140px !important;
  text-align: center !important;
}
.modal-footers .modal_obs__btn.validate {
  transition: 0.5s ease-out;
  color: white !important;
  background: #073b60;
  border: 1px solid #073b60;
}
.modal-footers .modal_obs__btn:hover {
  background: #b1dbfc;
  transition: 0.5s ease-out;
  color: #073b60;
}
.btn-validate {
  background: #073b60 !important;
  color: #fff !important;
  border-radius: 10px;
  /* font-size: 0.8rem; */
  margin: 0px 10px;
  width: 150px;
  text-align: center;
}
.btn-validate2 {
  background: #073b60;
  color: #fff;
  width: 200px;
  text-align: center;
}

.modal-footers .modal_obs__btn.validate:hover {
  transition: 0.5s ease-out;
  color: white;
  background: #075796;
  border: 1px solid #073b60;
}
.modal-size {
  max-width: 590px !important;
}
.crud_select_class {
  font-size: 0.8rem;
}

.create_image_modal {
  height: 640px;
}
