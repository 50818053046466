.LandingCarouselItemBody {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 75px;
  gap: 1rem;
}

.LandingCarouselItemTitle {
  font-size: 44px;
  color: var(--white);
  font-weight: 600;
  width: 950px;
}

.LandingCarouselItemText {
  font-size: 20px;
  color: var(--white);
  width: 900px;
}

.LandingCarouselItemBody > .signUpBtn {
  margin-top: 25px;
}

.Landing-indicators > button {
  height: 12px !important;
  width: 12px !important;
  border-radius: 100%;
}

@media (max-width: 1024px) {
  .LandingCarouselItemBody {
    justify-content: center;
    padding-top: 0;
  }

  .LandingCarouselItemTitle {
    font-size: 35px;
    width: 600px;
  }

  .LandingCarouselItemText {
    font-size: 16px;
    width: 700px;
  }
}

@media (max-width: 768px) {
  .LandingCarouselItemBody {
    justify-content: center;
  }

  .LandingCarouselItemTitle {
    font-size: 32px;
    width: 70%;
  }

  .LandingCarouselItemText {
    font-size: 16px;
    width: 80%;
  }

  .LandingCarouselImg {
    height: 350px;
    object-fit: cover;
  }
}

@media (max-width: 650px) {
  .LandingCarouselItemBody {
    justify-content: center;
  }

  .LandingCarouselItemBody > .signUpBtn {
    margin-top: 16px;
  }
}

@media (max-width: 550px) {
  .LandingCarouselImg {
    height: 400px;
  }
}

@media (max-width: 450px) {
  .LandingCarouselItemBody {
    justify-content: center;
    padding: 0 1rem;
    box-sizing: border-box;
  }

  .LandingCarouselItemTitle {
    font-size: 32px;
    width: 100%;
  }

  .LandingCarouselItemText {
    font-size: 16px;
    width: 100%;
  }

  .LandingCarouselImg {
    object-fit: cover;
  }
}

@media (max-width: 350px) {
  .LandingCarouselImg {
    height: 450px;
  }
}
