.ProductHighlights {
  padding-top: 60px;
}

.ProductWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 3rem 7.5rem;
  width: fit-content;
  margin: auto;
}

.ProductHighlights-Content {
  padding-bottom: 3rem;
}

.ProductHighlights-Product {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.middleProductText {
  border: solid black 2px;
  border-top: 0;
  border-bottom: 0;
}

.ProductHighlights-Product > img {
  height: 60px;
}

.ProductHighlights-ProductTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  padding: 1rem 0;
  color: var(--primaryBlue);
}

.ProductHighlights-ProductText {
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  padding: 0 2rem;
  color: var(--primaryBlue);
}

.ProductHighlights-btnWrapper {
  display: flex;
  justify-content: center;
  padding: 0 1rem 0;
}

@media (max-width: 1200px) {
  .ProductWrapper {
    padding: 3rem 5rem;
  }
}

@media (max-width: 1024px) {
  .ProductWrapper {
    padding: 3rem 2rem;
  }

  .ProductHighlights-Product {
    max-width: 468px;
  }

  .ProductHighlights {
    padding-top: 40px;
  }
}

@media (max-width: 768px) {
  .ProductWrapper {
    padding: 0rem 1.25rem;
    display: grid;
    grid-template-columns: 1fr;
  }

  .ProductHighlights-Product {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
  }

  .middleProductText {
    border: 0;
  }

  .middleProduct {
    border: solid black 2px;
    border-left: 0;
    border-right: 0;
  }
}
