.landingEntranceContainer {
  background: var(--communityBlue);
  width: 65%;
  margin: auto;
  color: #ffffff;
  padding: 1.5rem 2.5rem 1.75rem;
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
  border-radius: 0.5rem;
  min-width: 900px;
  max-width: 1050px;
}

.landingEntranceContainer > h2 {
  font-size: 2rem;
  padding-bottom: 0.75rem;
  font-weight: 600;
}

.landingEntranceText {
  font-size: 20px;
}

.landingEntranceSection {
  margin-bottom: 300px;
  position: relative;
  /* height: 80vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  background-color: var(--secondaryBlue);
}

.landingEntranceButtonWrapper {
  display: flex;
  justify-content: end;
  padding-top: 2rem;
}

.landingEntranceImage {
  width: 100vw;
}

@media (max-width: 1200px) {
  .landingEntranceContainer {
    min-width: 850px;
    max-width: 1050px;
  }
}

@media (max-width: 1024px) {
  .landingEntranceContainer {
    position: static;
    width: 100%;
    transform: translateY(0%);
    padding: 2rem 2rem 2rem;
    border-radius: 0;
    min-width: 0;
  }

  .landingEntranceContainer > h2 {
    font-size: 2.5rem;
    padding-bottom: 1rem;
  }

  .landingEntranceText {
    font-size: 1.25rem;
  }

  .landingEntranceSection {
    margin-bottom: 5rem;
  }

  .landingEntranceButtonWrapper {
    display: flex;
    justify-content: center;
    padding-top: 3rem;
  }
}

@media (max-width: 768px) {
  .landingEntranceContainer {
    padding: 1.5rem 1.25rem 1.5rem;
  }

  .landingEntranceContainer > h2 {
    font-size: 1.5rem;
  }

  .landingEntranceText {
    font-size: 1rem;
  }

  .landingEntranceSection {
    margin-bottom: 4.25rem;
  }
}

@media (max-width: 450px) {
  .landingEntranceButtonWrapper .smallBtn {
    width: 100%;
  }
}
