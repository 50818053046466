.titleBackground {
  color: var(--primaryBlue);
  font-size: 3rem;
  /* background: #b1dbfc; */
  height: 6.25rem;
  margin-bottom: 0;
  padding: 0rem 7.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}

@media (max-width: 1200px) {
  .titleBackground {
    padding: 0rem 5rem;
  }
}

@media (max-width: 1024px) {
  .titleBackground {
    font-size: 2.5rem;
    height: 5rem;
    padding: 0rem 2rem;
  }
}

@media (max-width: 768px) {
  .titleBackground {
    font-size: 2.25rem;
    padding: 0rem 1.25rem;
  }
}
