.admin-menu {
  background-color: rgba(242, 242, 242, 1);
  width: 264px;
  position: relative;
  z-index: 2;
  padding-top: 24px;
}

.admin-menu-item,
.admin-menu-btn-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 40px 0px 48px;

  height: 64px;
}

.curpoint {
  cursor: pointer;
}

.admin-menu-item.actived {
  background: #e0f1fe;
  color: #073860;
  font-weight: 600;
}

.admin-menu-item:hover {
  background: #e0f1fe;
}

.btn-logout {
  padding: 8px 24px;
}

.infouser {
  margin-bottom: 48px;
  padding-left: 48px;
  color: #073860;
}

.flname {
  font-weight: 600;
}

@media screen and (max-width: 580px) {
  .admin-menu {
 
    width: 200px;

  }
  .admin-menu-item,
.admin-menu-btn-item {

  padding: 0px 20px 0px 20px;
  font-size: .8rem;

}
.flname {
  font-size: .9rem;
}
.infouser {
  margin-bottom: 15px;
  padding-left: 15px;

}
.btn-logout {
  padding: 0;
}
}