.actionLists {
    position: absolute;
    background: white;
    top: 43px;
    right: 10px;
  }
  .actionItem {
    cursor: pointer;
  }
  
  .actionItem:hover {
    background: #b1dbfc;
    transition: 0.2s ease-in;
    color: #073b60;
  }
  .projectNameMobile {
    display: none !important;
  }
  .new__obs__btn {
    font-size: 13px;
  
    background: #ffffff;
    padding: 4px 8px;
    border: 1px solid #073b60;
    border-radius: 8px !important;
  }
  .new__obs__btn:hover {
    background: #b1dbfc;
    transition: 0.2s ease-in;
  }
  .new__obs__btn img{
  margin: 0px 5px;
  }
  @media (max-width: 1115px) {
   .new__obs__btn {
    padding: 4px 10px;
   }
    .new__obs__btn img{
      margin: 0px ;
      }
    .search__text{
      display: none !important;
    }
    .new__obs__text{
      display: none !important;
    }
  }
  @media screen and (min-width: 0px) {
    .date-picker-container {
      display: none !important;
    }
    .projectNameDesktop {
      display: none !important;
    }
    .projectNameMobile {
      display: block !important;
    }
  }
  