.search__container {
  position: relative;
}
.clear__search {
  background: #e6e6e6;
  color: #075796;
  border-radius: 20px;
  position: absolute;
  right: 5px;
  top: 0;
  margin-top: 5px;
  cursor: pointer;
  padding: 3px 8px;
}
.clear__search:hover {
  background: rgb(168, 166, 166);
  transition: 0.3s ease;
}
.search__container input:focus {
  outline: none;
  border: 0.5px solid #075796;
}
.rgt-search-highlight {
  background: #e6e6e6 !important;
  color: #075796 !important;
}
.leaflet-control-zoom {
  margin-bottom: 20px !important;
}

.disabled__btn {
  background: #073b60 !important;
  opacity: 0.3 !important;

  cursor: not-allowed !important;
  color: #ffffff !important;
}
.disabled__btn__secondary {
  background: #073b60;
  opacity: 0.3;

  cursor: not-allowed !important;
  color: #ffffff !important;
}
.btn-sort:hover {
  background: #b1dbfc !important;
}
.clear__filters__btn__disabled {
  cursor: not-allowed !important;
  background: white !important;
  color: rgb(190, 190, 190) !important;
  border: 1px solid rgb(190, 190, 190) !important;
}
.clear__filters__btn {
  cursor: pointer;
  background: white !important;
  border: 1px solid #073b60 !important;
  color: #073b60 !important;
}
.clear__filters__btn:hover {
  background: #b1dbfc !important;
  border: 1px solid #073b60 !important;
  color: #073b60 !important;
}
