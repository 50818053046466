.footerModal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  display: flex;
}

.footerModalBackground {
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 60%;
}

.footerModalContent {
  width: 620px;
  background-color: var(--white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  border-radius: 0.5rem;
  overflow: hidden;
}

.footerModalHeader {
  width: 100%;
  background-color: var(--seaBlue);
  padding: 10px 2rem;
  font-size: 24px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerModalExit {
  height: 1.25rem;
  cursor: pointer;
}

.footerModalMain {
  padding: 4rem 2rem;
}

.footerModalInputWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  position: relative;
}

.footerModalInputWrapper > label {
  font-size: 14px;
  font-weight: 400;
  color: #595959;
}

.footerModalInput {
  width: max-content;
  border-radius: 0.5rem;
  width: 300px;
  margin-top: 0.625rem;
}

.footerModalInput::placeholder {
  font-style: italic;
}

.footerModalEmailValid {
  position: absolute;
  bottom: -2px;
  transform: translateY(100%);
  color: green;
}

.footerModalFooter {
  width: 100%;
  height: min-content;
  background-color: #f2f2f2;
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.footerModalFooter .secondaryBtn {
  margin-top: 0;
  width: 200px;
}

/* Footer Subscribe Button */

.footerModalSubsribe {
  width: 200px;
  background: var(--orangeButton);
  padding: 0.4rem 2.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  color: var(--foregroundBlue);
  text-decoration: none;
  position: relative;
}

.footerModalSubsribe:hover {
  color: var(--foregroundBlue);
}

.footerModalSubsribe::after {
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  background-color: transparent;
  content: "";
  z-index: 2;
  border-radius: 8px;
}

.footerModalSubsribe:hover::after {
  border: solid 2px var(--foregroundBlue);
}

.footerModalSubsribe:hover {
  font-weight: 600;
}

.footerModalSubsribe:active:after {
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border: solid 4px var(--foregroundBlue);
}
