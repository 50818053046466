.SightingMap {
  position: relative;
  padding-top: 60px;
}

.SightingMapImg {
  width: 100%;
}

.SightingMapBtnWrapper {
  position: absolute;
  bottom: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 1rem 0;
}

.SightingMapBtnWrapper > .signUpBtn {
  padding: 0.4rem 1rem;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .SightingMap {
    position: relative;
    padding-top: 40px;
  }
}

@media (max-width: 768px) {
  .SightingMapBtnWrapper {
    bottom: 20px;
  }

  .SightingMapImg {
    width: 100%;
    height: 350px;
    object-fit: cover;
  }
}
