.questions_view{
    width: 80%;
    padding: 20px;
}
@media only screen and (min-width: 1600px) {
    .questions_view{
        width: 90%;
    }

}
.button__container{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 20px;

}
.question__id{
    padding: 0px 20px !important;
}
.modal_obs__btn.add_questions_btn {
    width:auto !important;
  }
  .question_label{
    padding-bottom: 10px;
  }
  .question_label__options{
    padding-bottom: 0px;
    font-size: .8rem;
  }
  .answer__btn__container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .answer__btn__container button{
    background: #042b4a;
    color: white;
    font-size: .7rem;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .answer__btn__container button:disabled{
    background: #073b60;
    opacity: 0.5;
    color: white;
    font-size: .7rem;
    cursor: not-allowed;
    padding: 5px 10px;
    border-radius: 5px;
  }
  .answers__container{
    height: 200px;
    background: white;
    width: 100%;
    margin-top: 50px;
    overflow-y: auto;
  
  }
  .answers__container  li{
    display: flex;
   
    padding: 0px 20px;
    justify-content: space-between;

  }
  .answers__container  small{
  cursor: pointer;
  color: gray;

  }