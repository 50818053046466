.menu-content {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
  position: absolute !important;
  background-color: #ffffff !important;
  width: 232px !important;
  /* top: 2px !important;
  left: 0px !important; */
}
.menu-content {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding-top: 5px !important;
  z-index: 9999 !important;
}
.menu-content {
  color: #073b60 !important;
}
.menu-content a {
  text-decoration: none !important;
  padding: 12px 24px !important;
  border-bottom: solid rgba(0, 0, 0, 0.15) !important;
  color: #073b60 !important;
  cursor: pointer !important;
}
.menu-content a:hover {
  background-color: rgba(177, 219, 252, 0.207) !important;
}
.dashboardActive{
  background-color: #073b60 !important;
}
.projectSelected {
  background-color: #e0f1fe;
}

.hambuger__menu {
  padding-bottom: 20px !important;
  margin-bottom: 20px !important;
  display: inline-block !important;
}
.mobileMenu__container {
  position: fixed !important;
  top: 0px;
  width: 100% !important;
  height: 100vh !important;
  transition: 0.3s ease-in;
  opacity: 9999 !important;
  z-index: 1000;
  background: white !important;
}
.mobile__menuTop {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.menu__mobile__text {
  font-size: 20px;
  font-weight: 600;
}

.mobile__menu__items {
  display: flex;
  justify-content: center;
  color: #073b60;
  font-weight: 500;
  align-items: center;
}
.mobile__menu__items.sub {
  margin-left: 20px;
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  color: #073b60;
  cursor: pointer;
  font-weight: 500;
  align-items: center;
}
.mobile__menu__items.sub a {
  color: #073b60;
}

.mobile__icon {
  width: 20px;
}

@media (min-width: 900px) {
  .mobileMenu__container {
    display: none;
  }
}
.projectLitLoading{
  cursor: not-allowed !important;
}