.latestNewCard-more {
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  width: 100%;
  color: var(--primaryBlue);
  cursor: pointer;
  text-decoration: none;
}

.latestNewCard-Open {
  max-height: min-content !important;
}

.latestNewCard-more:hover,
.latestNewCard-more:hover .latestNewCardBtnRotate > path {
  color: var(--communityBlue);
  fill: var(--communityBlue);
}

.latestNewCardBtnRotate > path {
  fill: var(--primaryBlue);
}

.latestNewCardBtnRotate {
  transform: rotate(270deg);
}

/* Css for cards */

.latestNewCard {
  border: transparent;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  /* height: fit-content; */
}

.latestNewsCard-imgWrapper {
  aspect-ratio: 1 / .8;
}

.latestNewsCard-img {
  object-fit: cover;
  min-height: 100%;
  margin: auto;
}

.latestNewCard-body {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.latestNewCard-title {
  min-height: 5rem;
  padding-bottom: 1rem;
  color: var(--primaryBlue);
}

.latestNewCard-title > h4 {
  font-size: 1.25rem;
  color: var(--primaryBlue);
  line-height: 30px !important;
}

.latestNewCard-bodyText {
  font-size: 1rem;
  color: var(--primaryBlue);
}

@media (max-width: 1300px) {
  .latestNewCard-body {
    padding: 1.5rem;
    padding-bottom: 3rem;
  }
}

@media (max-width: 1200px) {
  .latestNewCard-body {
    padding: 1.5rem;
    padding-bottom: 3rem;
  }

  .latestNewCard-title {
    min-height: 5rem;
    padding-bottom: 1rem;
  }
}

@media (max-width: 1024px) {
  .latestNewCard {
    display: inline-block;
    min-width: 370px;

  }

  .latestNewCard-body {
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .latestNewCard {
    display: inline-block;
    min-width: 350px;
  }
}

@media (max-width: 500px) {
  .latestNewCard {
    display: inline-block;
  }

  .latestNewCard-title {
    min-height: 3rem;
  }
}

@media (max-width: 350px) {
  .latestNewCard {
    display: inline-block;
  }
}
