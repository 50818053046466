.flex-container {
  display: flex;
  background-color: white;
  height: 100vh;
}

.admin-content {
  padding-top: 46px;
  padding-bottom: 100px;
  overflow: scroll;
}

.title-text {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  color: #042b4a;
}

.title-text2 {
  font-weight: 600;
  color: #042b4a;
}

.admin-btn-dark {
  width: 166px;
}

.export-btn{
  border-radius: 5px;
  font-weight: 600;
  height: 45px;
}

.eye-close {
  position: absolute;
  right: 11px;
  top: 31px !important;
}

.eclose {
  top: 35px !important;
}

.rowtop {
  border-bottom: 0.5px solid #cccccc;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.winput {
  width: 80% !important;
}

@media (min-width: 873px) {
  .inpttop {
    width: 160px;
  }
}

.bgr {
  background-color: red;
}

.posradio {
  position: absolute;
  right: 10px;
  bottom: 5px;
}

.pospub {
  position: absolute;
  left: 0px;
  bottom: 0px;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}

.form-select:focus {
  box-shadow: none;
}

.error {
  color: red;
}

.is-invalid {
  border: 1px solid red;
  outline: none !important;
  box-shadow: none !important;
}
.is-invalid:focus {
  border: 1px solid red !important;
  outline: none !important;
  box-shadow: none !important;
}

.h90 {
  height: 90px;
}

.poserr {
  position: relative;
  bottom: 15px;
}

.modal_obs__btn.this {
  width: 175px !important;
}

.divide {
  margin-top: 15px;
}

.procloseico {
  position: absolute;
  top: 10px;
  right: 30px;
}

.txtadd {
  position: relative !important;
  left: 3px !important;
  bottom: 3px !important;
}

.modal_obs__btn.this {
  z-index: 10 !important;
  background-color: white;
  width: 175px !important;
  border: 2px solid #042b4a;
}

.succedmsg {
  margin: 0px;
  left: 0px;
}

.margePublic {
  margin-left: 20px;
}

