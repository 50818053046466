.flex-container {
  display: flex;
  background-color: white;
  height: 100vh;
}

.admin-content {
  padding-top: 46px;
  padding-bottom: 100px;
  overflow: scroll;
}

.title-text {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  color: #042b4a;
}

.title-text2 {
  font-weight: 600;
  color: #042b4a;
}

.admin-btn-dark {
  width: 166px;
}

.eye-close {
  position: absolute;
  right: 11px;
  top: 37px;
}

.eclose {
  top: 40px !important;
}

.rowtop {
  border-bottom: 0.5px solid #cccccc;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.winput {
  width: 80% !important;
}

@media (min-width: 873px) {
  .inpttop {
    width: 160px;
  }
}

.posradio {
  position: absolute;
  right: 10px;
  bottom: 5px;
}

.pospub {
  position: absolute;
  left: 0px;
  bottom: 0px;
}

.colb {
  color: #0061b1 !important;
}

.hov {
  cursor: pointer;
}

.modal_obs__btn.this {
  width: 175px !important;
}

.txtplusd {
  position: relative;
  bottom: 5px !important;
  right: 8px;
}

.txtaddd {
  position: relative;
  bottom: 9px !important;
}
