.landingFooter {
  background-color: #b1dbfc;
  padding: 6.25rem 7.5rem;
  color: var(--primaryBlue);
}

.landingFooterLearnMore {
  display: flex;
  justify-content: space-between;
}

.landingFooterQuickLinksDisabled {
  opacity: 50%;
}

.landingFooterMd {
  background-color: #b1dbfc;
  padding: 3rem 4rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.footerRow {
  align-items: center;
  justify-content: center;
}

.landingFooterMdWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: var(--primaryBlue);
}

.footerImageColumn {
  width: fit-content;
}

.footerLinksColumn {
  margin-top: 3.56rem;
  margin-bottom: 4.72rem;
}

.landingFooterHeaders {
  font-weight: 600;
  margin-bottom: 0;
  padding-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primaryBlue);
  width: fit-content;
}

.footerLinksColumn h5 {
  color: var(--primaryBlue);
}

.footerLinksColumn a h5:nth-child(1) {
  margin-bottom: 1.88rem;
}

.linksHolder {
  margin-right: 8.68rem;
}

.footerButton {
  margin-top: 0.38rem;
  line-height: 2.25rem;
  letter-spacing: 0.01em;
  background-color: var(--primaryBlue);
  padding: 1rem 1.5rem;
  margin-right: 6rem;
  border: none;
}

.footerButton:hover {
  background-color: var(--primaryBlue) !important;
  filter: brightness(0.9);
}

.landingFooterConnect {
  display: flex;
  gap: 1rem;
  /* padding-top: 1rem; */
}

.footerNewsLetter {
  width: max-content;
  border-radius: 0.5rem 0 0 0.5rem;
  width: 300px;
  border: 0;
}

.footerNewsLetter::placeholder {
  font-style: italic;
}

.landingFooterQuickLinksWrapper {
  width: fit-content;
}

.landingFooterContactIcons {
  padding-right: 0.25rem;
  width: 1rem;
  height: 1rem;
}

.landingFooterQuickLinks {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: fit-content;
  font-size: 1.15rem;
}

.landingFooterQuickLinks > a {
  cursor: pointer;
  color: var(--primaryBlue);
  text-decoration: none;
}

.landingFooterQuickLinks > a:hover {
  color: var(--communityBlue);
}

.landingFooterSubscribe {
  font-size: 1.15rem;
}

.landingFooterEmailSenderWrapper {
  background-color: var(--primaryBlue);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  border: 0 0.5rem 0.5rem 0;
}

.landingFooterInfoWrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 3rem;
  align-items: end;
  gap: 5rem;
}

.landingFooterContactInfo {
  font-size: 1rem;
}

.landingFooterContactWrapper {
  display: flex;
  flex-direction: column;
  /* margin-top: 2rem; */
  padding-top: 0;
  gap: 0.5rem;
  width: fit-content;
}

.landingFooterInfo {
  display: flex;
  width: fit-content;
  flex-direction: column;
}

.landingFooterInfo > h4 {
  font-size: 1.1rem;
  width: fit-content;
  margin-bottom: 0;
  padding-bottom: 1.5rem;
}

.landingFooterInputWrapper {
  border: 0.5px black solid;
  overflow: hidden;
  margin-top: 0.8rem;
  display: flex;
  width: min-content;
  border-radius: 0.5rem;
  cursor: pointer;
}

.landingFooterConnectLarge {
  display: block;
}

.landingFooterConnectSmall {
  display: none;
}

.landingFooterContactInfo.landingFooterAmmcoEmail {
  cursor: pointer;
}

.landingFooterAmmcoEmail:hover {
  color: var(--communityBlue);
}

.landingFooter .secondaryBtn {
  margin-top: 0.8rem;
}

@media (max-width: 1400px) {
  .landingFooterContactInfo {
    font-size: 0.85rem;
  }
}

@media (max-width: 1200px) {
  .landingFooter {
    padding: 6.25rem 5rem;
  }

  .landingFooterInfoWrapper {
    gap: 5rem;
  }

  .landingFooterContactInfo {
    font-size: 0.75rem;
  }
}

@media (max-width: 1024px) {
  .landingFooter {
    padding: 5rem 2rem;
  }

  .landingFooterLearnMore {
    flex-direction: column;
    gap: 3rem;
  }

  .landingFooterInfoWrapper {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 5rem;
  }

  .landingFooterHeaders {
    font-weight: 600;
  }

  .landingFooterConnectLarge {
    display: none;
  }

  .landingFooterConnectSmall {
    display: block;
  }

  .landingFooterContactInfo {
    font-size: 0.9rem;
    width: fit-content;
  }
}

@media (max-width: 768px) {
  .landingFooter {
    padding: 3rem 1.25rem;
  }

  .landingFooterInfoWrapper {
    gap: 3rem;
  }

  .landingFooterContactWrapper {
    display: flex;
    flex-direction: column;
  }

  .landingFooterContactInfo {
    font-size: 1rem;
  }
}

@media (max-width: 450px) {
  .landingFooterInputWrapper {
    width: 100%;
  }

  .footerNewsLetter {
    width: 100% !important;
  }
}
